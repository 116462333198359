
.category-blockquote {

  @include tablet {
    align-items: center;
    display: flex;
  }

  p {
    font-family: $font1;
  }
  /**WHITE OVERRIDES**/
  &--white {
    background: #fff;
    color: #162225;
    .category-blockquote__name {
      color: #162225;
    }
    .category-blockquote__id, .category-blockquote__quote {
      background: #fff;
      border: 3px solid #009FD0;
      color: #162225;
    }
  }

  &--reversedesktop {
    @include tablet {
      flex-flow: row-reverse;
      .category-blockquote__id {
        margin-left: 5px;
        margin-right: 0px;
      }
      .category-blockquote__id {
        border-radius: 0 10px 10px 0;
      }
      .category-blockquote__quote {
        border-radius: 10px 0 0 10px;
      }
    }
  }
  .is-size-6 {
    font-weight: 400;
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }
}

.category-blockquote__img {
  position: relative;
  //width: 120px;
  //height: 120px;
  margin: 20px auto;

  @include tablet-only {
    margin: 20px 40px 20px 0;
  }

  img {
    width: 100%;
    height: 100%;
  }

  /*&:before {
    position: absolute;
    content: '';
    width: 140px;
    height: 140px;
    display: block;
    border: 4px solid #009FD0;
    border-radius: 50%;
    top: -10px;
    left: -10px;
    z-index: 10;
  }*/
}

.category-blockquote__id,
.category-blockquote__quote {
  padding: 14px 10px;
  text-align: center;
  @include desktop {
    padding: 30px 20px;
  }
}

.category-blockquote__id {
  border-radius: 10px 10px 0 0;
  color: #d7d7d7;
  padding: 0;

  @include tablet {
    width: 25%;
  }

  @include tablet-only {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0 0;
  }

  @include desktop {
    width: 270px;
    margin-right: 5px;
    border-radius: 10px 0 0 10px;
    padding: 5px;
  }
}

.category-blockquote__id-bio {
  text-align: center;

  @include tablet-only {
    width: 200px;
  }

  @include tablet {
    text-align: left;
  }

  p {
    line-height: 1.5rem;
    font-size: .9rem;
  }
}

.category-blockquote__name {
  font-size: 1.3rem !important;
  margin-top: 10px!important;
  font-weight: 400!important;
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
}

.category-blockquote__quote {
  border-radius: 0 0 10px 10px;

  @include tablet {
    width: 75%;
  }

  @include desktop {
    width: calc(100% - 245px);
    border-radius: 0 10px 10px 0;
  }

  p {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 10px;
    position: relative;

    @include tablet {
      font-size: 2rem;
      line-height: 1.75rem;
      margin: 0 10px;
    }

    @include desktop {
      margin: 0 60px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 16px;
      background-image: url(../images/focus/svg/blockquote-symbol-blue.svg);
      background-size: contain;
      @include tablet {
        height: 32px;
        width: 40px;
      }
    }

    &:before {
      left: -20px;
      top: 0;
      @include desktop {
        left: -40px;
      }

    }

    &:after {
      right: -20px;
      bottom: 0;
      transform: rotate(180deg);
      @include desktop {
        right: -40px;
      }
    }
  }
}

.category-blockquote__quote-text {
  font-size: 2rem
}

.category-blockquote--test {
  margin-top: 10px;
  .category-blockquote__id {
    background: #fff;
    //border: 4px solid #f15a29;
    color: #162225;

    .category-blockquote__name {
      color: #162225;
    }
  }
  .category-blockquote__quote {
    background-color: #fff;
    //border: 4px solid #f15a29;
    p {
      color: #122225;
    }
  }

  .category-blockquote__img {
    &:before {
      display: none;
    }
    img {
      @include tablet {
        max-width: 300px;
      }
    }

  }
  .category-blockquote__quote-text {
    p {
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 16px;
        background-image: url(../images/test/blockquote-symbol-orange.svg);
        background-size: contain;

        @include tablet {
          width: 40px;
          height: 32px;
        }
        &:before {
          left: -20px;
          top: 0;

          @include tablet {
            left: -29px;
            top: -20px;
          }

          @include desktop {
            left: -55px;
            top: 0px
          }

        }

        &:after {
          right: -20px;
          bottom: 0;
          transform: rotate(180deg);

          @include tablet {
            right: -20px;
            bottom: -10px;
          }

          @include desktop {
            right: -45px;
            bottom: -10px;
          }

          @include desktop {
            bottom: 0;
          }
        }
      }
    }



  }
}
.category-blockquote--generic {
  .category-blockquote__quote-text p {
    &:before, &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 16px;
      background-image: url(../images/cms/blockquote-symbol-black.svg);
      background-size: contain;
      @include tablet {
        width: 40px;
        height: 32px;
      }
    }
    &:before {
      left: -20px;
      top: 0;

      @include tablet {
        left: -29px;
        top: -20px;
      }

      @include desktop {
        left: -55px;
        top: 0px
      }

    }
  }
  &.smallquotes {
    p {
      &:before, &:after {
        height: 24px;
        width: 30px;
      }
    }
  }
}

//Changing to the burn colour for testimonials
body.category-hunter-burn {
  .category-blockquote--test .category-blockquote__quote-text p:before, .category-blockquote--test .category-blockquote__quote-text p:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 16px;
    background-image: url(../images/burn/blockquote-symbol-yellow.svg);
    background-size: contain;
    @include tablet {
      width: 40px;
      height: 32px;
    }
    &:before {
      left: -20px;
      top: 0;

      @include tablet {
        left: -29px;
        top: -20px;
      }

      @include desktop {
        left: -55px;
        top: 0px
      }

    }
  }
}
