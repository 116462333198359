// Colour helper classes
.grey {
  color: $light-black;
}

.red {
  color: $red;
}

.off-black {
  color: #0c0e12
}

.orange {
  color: $hunter-orange;
}

.yellow {
  color: $hunter-yellow;
}

.blue {
  color: $hunter-blue;
}
.focus-blue {
  color: #009FD0;
}

.dark-blue {
  //color: #0d4450;
}
.white {
  color: white !important;
}

.light-grey {
  color: $mid-light-grey
}

.text-spaced {
  letter-spacing: 2px;
}
.hunter-yellow-text {
  color: #faa619;
}

.burn {
  &--yellow {
    color: $burn-yellow
  }
  &--green {
    color: $burn-green
  }
  &--peach {
    color: $burn-peach
  }
}

.test {
  &--orange {
    color: $test-orange
  }
  &--blue {
    color: $test-blue
  }
  &--peach {
    color: $test-peach
  }
}

.focus {
  &--blue {
    color: $focus-blue
  }
  &--green {
    color: $focus-green
  }
  &--light-blue {
    color: $focus-light-blue
  }
}

/**Border helpers**/

.border-green {
  border: 4px solid #6dab72!important;
}
.border-blue {
  border: 4px solid #009FD0!important;
}

.border-light-blue {
  border: 4px solid #9ad7f1!important;
}
.border-orange {
  border: 4px solid #f15a29!important;
}

.border-mid-blue {
  border: 4px solid #377f9b!important;
}

.border-testimonial-blue {
  border: 4px solid #272991;
}
.border-burnyellow {
  border: 4px solid #faa619!important;
}
.border-burngreen {
  border: 4px solid #82a31e!important;
}
.border-burnpeach {
  border: 4px solid #f15a29!important;
}

.border-black {
  border: 4px solid #000!important;
}

.burn-hr {
  background-color: #faa619;
  height: 4px;
}

.test-hr {
  background-color: #f15a29;
  height: 4px;
}

.focus-hr {
  background-color: #009fd0;
  height: 4px;
}

.black-hr {
  background-color: #000000;
  height: 4px;
}

.bordered {
  background: rgba(255,255,255,0.75);
  border: 4px solid #827d7f;
  border-radius: 16px 16px;
  @include tablet {
    border: 5px solid #827d7f;
  }
}
.border-bottom {
  background: rgba(255,255,255,0.75);
  border-bottom: 4px solid #827d7f;
  @include tablet {
    border-bottom: 5px solid #827d7f;
  }
}
.background-grey {
  background: rgba(237,237,237,0.88);
  border-radius: 16px 16px;
}
//*bulma extra helpers!

.is-flex-direction-column {
  flex-flow: column;
}
.is-align-items-flex-start {
  align-items: flex-start;
}
.is-justify-content-center {
  justify-content: center;
}
// Layout

@mixin bg-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  @include desktop {
    align-items: flex-end;
  }
}

.bg-cover {
  @include bg-cover;
}

.bg-dark-grey {
  background-color: $light-black;
}

.bg-light-grey {
  background-color: $light-grey;
}

.bg-lightest-grey {
  background-color: $lightest-grey;
}

.bg-verylight-grey {
  background-color: #f9f9f9;
}

.bg-mid-grey {
  background: #EBEBEB;

}
.bg-black {
  background-color: #000;
}

.bg-orange {
  background-color: $hunter-orange
}

.bg-offblack {
  background-color: $off-black;
}

.flex-vertical-center {
  @include flex-center;
}

.img-block {
  display: block;
  position: relative;
  z-index: 10;
}

.img-center {
  margin: 0 auto;
}

.max-width-image {
  width: 100%;
  max-width: 300px;
  margin: auto;
}

.margin-zero {
  margin: 0
}

// Font helper classes

.s-text {
  @include s-text;
}

.sm-text {
  @include sm-text;
}

.reg-text {
  @include reg-text;
}

.m-text {
  @include m-text;
}

.ml-text {
  @include ml-text;
}

.l-text {
  @include l-text;
}

.xl-text {
  @include xl-text;
}

.xxl-text {
  @include xxl-text;
}

.title-font {
  font-family: $font1
}

.reg-font {
  font-family: $font2
}

.light {
  font-weight: 400;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.caps {
 text-transform: uppercase;
}

.italic {
  font-style: oblique;
}

.text-shadow {
  text-shadow: 1px 1px rgba(0,0,0, .3);
}

.block-mobile {
  display: block;

  @include tablet {
    display: inline-block;
  }
}

.block{
  display: block
}

.text-center-mobile {
  text-align: center;

  @include desktop {
    text-align: left;
  }
}

.zero-bottom {
  margin-bottom: 0;
}


// Section padding helpers

.image-bg-padded {
  padding: 50px 0 300px;

  @include tablet {
    padding: 50px 0 400px;
  }
}

.image-bg-padded-equal--small {
  padding: 50px 0;

  @include tablet {
    padding: 100px 0;
  }
}

.image-bg-padded-equal {
  padding: 100px 0;

  @include tablet {
    padding: 250px 0;
  }
}

.image-bg-padded-equal--large {
  padding: 100px 0 250px;

  @include tablet {
    padding: 200px 0 300px;
  }
}

.v-align {
  vertical-align: middle;
  margin-right: 5px;
}

.bdb {
  border-bottom: 2px solid $light-grey;
  padding-bottom: 1rem;

  @include tablet {
    padding-bottom: 2rem;
    padding-top: 1rem;
  }
}

.bdt {
  border-top: 2px solid $light-grey;
  padding-top: 1rem;

  @include tablet {
    padding-top: 3rem;
  }
}

.zero-padding, .zp {
  padding: 0;
}

.zero-padding-top, .zpt {
  padding-top: 0!important;
}

.zero-padding-bottom, .zpb {
  padding-bottom: 0!important;
}

.zero-padding-right, .zpr {
  padding-right: 0;
}

.zero-padding-left, .zpl {
  padding-left: 0;
}

.zero-margin, .zm {
  margin: 0;
}

.zero-margin-top, .zmt {
  margin-top: 0!important;
}

.zero-margin-bottom, .zmb {
  margin-bottom: 0!important;
}

.zero-margin-right, .zmr {
  margin-right: 0;
}

.zero-margin-left, .zml {
  margin-left: 0;
}

.zero-padding-bottom-mobile {
  @media (max-width: 768px){
    padding-bottom:0!important;
  }
}
.zero-padding-top-mobile {
  @media (max-width: 768px){
    padding-top:0!important;
  }
}

.padding-left-zero-mobile {
    @media (max-width: 575px) {
      padding-left:0;
    }
}

.padding-right-zero-mobile {
  @media (max-width: 575px) {
    padding-right:0;
  }
}
