.hero--home {
  //background-image: url(../images/cms/hunter-home-hero-mob-alt.jpg);
  .hero__title {
    @include tablet {
      text-align: center;
    }
    @include desktop {
      font-size: 2.7rem;
      margin-bottom: 0.25rem;
      text-align: center;
    }
  }
  .hero__subtitle {
    @include tablet {
      margin-bottom: 1.5rem!important;
      text-align: center;
    }
    @include desktop {
      margin-bottom: 0.5rem!important;
      text-align:center;
    }
  }


  &.hero-simplified {
    background: black;
    min-height: 6vh;
    @include desktop {
      min-height: 75vh;
    }
    .hero__title {
      font-size: 1.30rem;
      @include tablet {
        font-size: 1.75rem;
      }
    }
    .hero__logo {
      min-height: 60px;
      @include desktop {
        min-height: 95px;
      }
      img {
        margin-bottom: -40px;
        width: 200px;
        @include tablet {
          margin-bottom: -20px;
        }
        @include desktop {
          width: 300px;
        }
      }
    }
    .product-shot {
      margin-bottom: -30px;
      max-width:90%;
      @include tablet {
        max-width: 300px;
      }
      @include desktop {
        margin-top: -40px;
        max-width: 400px;
      }
    }
  }


  .hero__product {
    margin: 0 auto;
    position: absolute;
    transform: rotate(24deg);
    lefT: 0;
    width: 250px;
    @include tablet {
      width: 370px;
    }
    @include desktop {
      left: unset;
      margin: auto;
      transform: none;
      width: auto;
    }
  }

  .hunter-test-bottle {
    left: initial;
    margin-left: -19%;
    @include tablet {
      margin-left:0;
    }
    @include desktop {
      right: 220px;
    }
  }

  .hunter-burn-bottle {
    left: inherit;
    right: -19%;
    @include tablet {
      right: 0;
    }
    @include desktop {
      //right: -250px;
      right: -190px;
      top: -300px;
    }
  }

  .hunter-focus-bottle {
    left: 47%;
    margin-top: 63px;
    transform: rotate(24deg) translate(-50%, 0);
    @include tablet {
      margin-top:72px;
    }
    @include desktop {
      left: unset;
      margin-top: auto;
      transform: none;
      right: -20px;
    }
  }

  &.home--new2023 {
    background: black;
    .hero-content {
      .content {
        left: -510px;
        top: -150px;
      }
    }
    .hunter-test-bottle {
      right: -406px;
    }
    .hunter-focus-bottle {
      right: -636px;
    }
    .hunter-burn-bottle {
      right: -790px;
      top: -300px;
    }
  }

}

.pseudo-lines {
  position: relative;

  &:before,
  &:after {
    content: "";
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 20%;
    display: none;

    @include tablet {
      display: inline-block;
    }
  }

  &:before {
    background-image: linear-gradient(to right, transparent 0%, white 100%);
    right: 1.5em;
  }

  &:after {
    background-image: linear-gradient(to left, transparent 0%, white 100%);
    left: 1.5em;
  }
}

.pseudo-lines--dark {
  &:before {
    background-image: linear-gradient(to right, transparent 0%, $light-black 100%);
  }

  &:after {
    background-image: linear-gradient(to left, transparent 0%, $light-black 100%);
  }
}

// Home page sections

.home-section--1 {

  .intro-hunter-range {
    max-width: 270px;
    width: 100%;
    position: relative;

    @include tablet {
      max-width: 400px;
    }
  }

  h3 {
    margin-bottom: 0;
    @include tablet {
      margin-bottom:3rem;
    }
  }
  .testimonial {
    height: 100%;
    margin:0;
    opacity:1;
    width: 100%;
    .testimonial__person {
      padding:0;
      img {
        background: white;
        border-radius: 50%;
        margin-top: 15px;
        width: 40%;
        @include tablet {
          width: 30%;
        }
      }
    }
  }
}


.home-section--2 {
  img.main-img {
    border-radius: 10px;
  }
  /**mobile reordering**/
  .order-1 {
    order:1;
    @include tablet {
      order: initial;
    }
  }
  .order-2 {
    order:2;
    @include tablet {
      order: initial;
    }
  }
  .order-3 {
    order:3;
    @include tablet {
      order: initial;
    }
  }
  .order-4 {
    order: 4;
    @include tablet {
      order: initial;
    }
  }
  .order-5 {
    order: 5;
    @include tablet {
      order: initial;
    }
  }
}

.home-section--3 {
  .container {
    padding-top:0;
    @include tablet {
      padding-top: 2rem;
    }
  }
  .is-flex {
    flex-flow: column-reverse;
    @include tablet {
      flex-flow: row;
    }
  }
  .main-img {
    img {
      border-radius: 10px;
    }
  }
}

.home-section--4 {
  img {
    //border-radius: 10px!important;
  }
}
.home-section--5 {
  img {
    border-radius: 10px;
  }
  .is-flex {
    @include tablet {
      flex-direction: row-reverse;
    }
  }

}

.home-section--6 {
  .owl-carousel .owl-item img {
    margin: 0px auto 0;
    width: 25%;
  }
  .testimonial {
    min-height: 490px;
    @include tablet {
      min-height: 420px;
      width: 480px;
    }
    @include desktop {
      min-height: 420px;
      width: 480px;
    }
  }
  &.fivereasons {
    background: #ebebeb;
    .testimonial.border-testimonial-blue {
      border: 4px solid #000;
    }
    position: relative;
    @include widescreen {
      padding-bottom: 8rem;
    }

    .testimonials {
      margin-bottom: 1rem;
      .testimonial {
        border-radius: 0;
        min-height: auto;
        opacity:1;
        background: #ffffff;
        display: flex;
        flex-flow: column;
        height: 100%;
        justify-content: center;
        padding: 2rem 1.25rem;
        margin: 0;
        @include desktop {
          min-height: 410px;
        }
        .is-size-5 {
          margin-bottom: 1rem;
        }
        .testimonial__quote {
          margin-bottom:0;
          padding:0;
          .category-blockquote__name {
            font-weight: 700!important;
          }
        }
        .is-size-5 {
          align-items: center;
          justify-content: center;
          display: flex;
        }

      }
      .testimonial__person {
        padding: 0.5rem;
        img {
          margin-bottom: 0.5rem;
          max-height: 95px;
          @include tablet {
            margin-bottom: 0;
          }
          @include desktop {
            max-height: 75px;
          }
        }
      }
    }
    .testimonials__navigation {
      display:flex;
      height: 40px;
      justify-content: flex-end;
      position: relative;
      .carousel__prev, .carousel__prev--testimonials {
        background: black;
        border-radius:0;
        color: white;
        bottom:0;
        font-size:22px;
        height: 40px;
        left:0;
        margin:0;
        margin-right:10px;
        position: relative;
        right: 0;
        text-align:center;
        top: initial;
        width: 40px;
      }
      .carousel__next, .carousel__next--testimonials {
        background: black;
        border-radius:0;
        color: white;
        font-size: 22px;
        height: 40px;
        bottom:0;
        margin:0;
        margin-right: 10px;
        right: 0;
        position: relative;
        text-align:center;
        top: initial;
        width: 40px;
      }
    }
    .owl-stage {
      align-items: stretch;
      display:flex;
    }

    .testimonials-2 {
      .testimonial {
        width: 320px;
        img {
          border-radius: 50%;
          margin: 20px auto;
          max-width: 100px;
          width: 80%;
        }
      }
    }
    .testimonials__navigation--quotes {
      margin-top:1rem;
      position: relative;
    }
    h2 {
      text-align: center;
    }
  }
  &.customer-quotes  {
    background: #ebebeb;
    color: #222222;

    .testimonial.border-testimonial-blue {
      border: 4px solid #000;
    }
    position: relative;
    @include widescreen {
      padding-bottom: 4rem;
    }
    .testimonial {

    }
    .testimonial {
      border-radius: 0;
      min-height: auto;
      opacity:1;
      background: #ffffff;
      display: flex;
      flex-flow: column;
      height: 100%;
      justify-content: center;
      padding: 1.25rem;
      height: 100%;
      margin: 0;
      min-height: 490px;
      @include desktop {
        min-height: 460px;
        width: 480px!important;
      }
      .is-size-5 {
        margin-bottom: 1rem;
      }
      .testimonial__quote {
        margin-bottom:0;
        padding:0;
      }
      .is-size-5 {
        align-items: center;
        justify-content: center;
        display: flex;
        min-height: 56px;
      }
    }
    .owl-stage {
      align-items: stretch;
      display:flex;
    }
    .carousel__prev, .carousel__prev--testimonials {
      background: black;
      border-radius:0;
      color: white;
      bottom:6%;
      font-size:22px;
      height: 40px;
      left:0;
      right: -89%;
      text-align:center;
      top: initial;
      width: 40px;
    }
    .carousel__next, .carousel__next--testimonials {
      background: black;
      border-radius:0;
      color: white;
      font-size: 22px;
      height: 40px;
      bottom:6%;
      right: -95%;
      text-align:center;
      top: initial;
      width: 40px;
    }
    .testimonial__quote {
      .category-blockquote__quote-text {
        text-align: center;
      }
    }
    .carousel__prev, .carousel__next  {
      bottom: 16%;
    }
    .btn--bordered-black {
      background: $white;
      &:hover {
        background: $black;
        color: $white;
      }
    }
  }
}

.home-section--7 {
  .card {
    h2 {
      @include desktop {
        font-size: 2rem;
      }
    }
  }
}

.home-section--8 {
  overflow: hidden;
  @include desktop {
    min-height: 80vh;
  }
}
.home-section--9 {
  .is-flex {
    flex-flow: column;
    @include tablet {
      flex-flow: row-reverse;
    }
    @include desktop {
      //min-height: 80vh;
    }
  }
}
.home-section--10 {
  @include desktop {
    min-height: 70vh;
  }
}

.home-section--11 {
  .category-blockquote__quote {
    width: 100%;
  }
}

.home-section--12 {

  img {
    border-radius: 10px;
  }
  .order-2 {
    order:2;
    @include tablet {
      order: initial;
    }
  }
  .order-3 {
    order:3;
    @include tablet {
      order: initial;
    }
  }
}

.home-section--13 {
  img {
    border-radius: 10px;
  }
}

.home-section--14 {
  overflow: hidden;
}


.single-competition {
  h2 {
    margin:0;
    padding: 1rem;
    padding-bottom:0;
  }
  ul {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    li {
      font-weight: 700;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      font-size: 0.9rem;
      line-height: 1rem;
      p {
        font-size: 0.9rem;
        line-height: 1rem;
        span {
          line-height: 1.25rem;
        }
      }
    }
    &.hunter-test {
      .muted-orange {
        color: #ae6147;
      }
      li {
        border: 3px solid #f15a29;
        border-radius: 0 10px 10px 0;
        color: #f15a29;
        position: relative;
        &:before {
          background: #f15a29;
          content: '';
          display:none;
          height: 3px;
          left: -195px;
          position: absolute;
          top: 50%;
          width: 200px;
          @include desktop {
            display: block;
          }
        }
      }
      &.filled {
        li {
          background: #f15a29;
          color: $white;
        }
      }
      &.alternative {
        li {
          border: none;
          color: inherit;
          font-weight: inherit;
          margin-bottom:0;
          text-transform: none;
        }
      }
      &.single-comparison__left {
        li {
          //border: 3px solid #f15a29;
          //border-radius: 0 10px 10px 0;
          //color: #f15a29;
          position: relative;
          &:before {
            background: #f15a29;
            content: '';
            display: none;
            height: 3px;
            left: auto;
            position: absolute;
            right: -195px;
            top: 50%;
            width: 200px;
            @include desktop {
              display: block;
            }
          }
        }
      }
    }

    &.hunter-yellow {
      .muted-yellow {
        color: #b29564;
      }
      li {
        border: 3px solid #faa619;
        border-radius: 0 10px 10px 0;
        color: #f15a29;
        position: relative;
        &:before {
          background: #faa619;
          content: '';
          display: none;
          height: 3px;
          left: -195px;
          position: absolute;
          top: 50%;
          width: 200px;
          @include desktop {
            display: block;
          }
        }
      }
      &.filled {
        li {
          background: #faa619;
          color: $white;
        }
      }
      &.alternative {
        li {
          border: none;
          color: inherit;
          font-weight: inherit;
          margin-bottom:0;
          text-transform: none;
        }
      }
      &.single-comparison__left {
        li {
          //border: 3px solid #f15a29;
          //border-radius: 0 10px 10px 0;
          //color: #f15a29;
          position: relative;
          &:before {
            background: #faa619;
            content: '';
            display: none;
            height: 3px;
            left: auto;
            position: absolute;
            right: -195px;
            top: 50%;
            width: 200px;
            @include desktop {
              display: block;
            }
          }
        }
      }
    }
    &.hunter-blue{
      .muted-blue {
        color: #809298;
      }
      li {
        border: 3px solid #009FD0;
        border-radius: 0 10px 10px 0;
        color: #009FD0;
        position: relative;
        &:before {
          background: #009FD0;
          content: '';
          display: none;
          height: 3px;
          left: -195px;
          position: absolute;
          top: 50%;
          width: 200px;
          @include desktop {
            display: block;
          }
        }
      }
      &.filled {
        li {
          background: #009FD0;
          color: $white;
        }
      }
      &.alternative {
        li {
          border: none;
          color: inherit;
          font-weight: inherit;
          margin-bottom:0;
          text-transform: none;
        }
      }
      &.single-comparison__left {
        li {
          //border: 3px solid #f15a29;
          //border-radius: 0 10px 10px 0;
          //color: #f15a29;
          position: relative;
          &:before {
            background: #009FD0;
            content: '';
            display: none;
            height: 3px;
            left: auto;
            position: absolute;
            right: -195px;
            top: 50%;
            width: 200px;
            @include desktop {
              display: block;
            }
          }
        }
      }
    }
  }
  img {
    display: block;
    margin: 0 auto;
    width: 100px;
    @include tablet {
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
      top: 273px;
      width: 175px;
      z-index: 1;
    }
    @include desktop {
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
      top: 210px;
      width: 275px;
      z-index: 1;
    }
  }
  .is-size-3 {
    @include tablet {
      font-size: 1.75rem!important;
    }
    @include desktop {
      font-size: 2rem!important;
    }
  }
  &--alt {
    img {
      display: block;
      margin: 0 auto;
      width: 100px;
      @include tablet {
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
        top: 273px;
        width: 175px;
        z-index: 1;
      }
      @include desktop {
        left: 30%;
        position: absolute;
        transform: translate(-50%, 0);
        top: 210px;
        width: 275px;
        z-index: 1;
      }
    }
  }
  &--huntertest {
    img {
      transform: translate(0%, -0%);
      width: 200px;
      @include tablet {
        transform: translate(-50%, -75%);
        width: 250px;
      }
      @include desktop {
        transform: translate(-50%, -50%);
        width: 250px;
      }
    }
    .title-padding {
      @include desktop {
        padding-bottom: 5rem;
      }
    }
  }
}

.comparison {
  align-items: flex-start;
  .order-mobile {
    @media (max-width: 767px) {
      order:1;
    }
  }
  .order-1-tablet {
    @include tablet {
      order:1;
    }
    @include desktop {
      order: initial;
    }
  }
  .order-2-tablet {
    @include tablet {
      order:2;
    }
    @include desktop {
      order: initial;
    }
  }
  .order-3-tablet {
    @include tablet {
      order:3;
    }
    @include desktop {
      order: initial;
    }
  }
  h2 {
    margin:0;
    margin-bottom: 1rem!important;
    padding: 1rem;
    padding-bottom:0;
    text-align: center;
    @include tablet {
      margin-bottom: 1.5rem;
      text-align: left;
    }
  }
  ul {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    li {
      font-weight: 700;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      font-size: 0.9rem;
      line-height: 1rem;
      p {
        font-size: 0.9rem;
        line-height: 1rem;
        span {
          line-height: 1.25rem;
        }
        .fa {
          margin-right: 10px;
        }
      }
    }

    p {
      padding: 0.5rem;
      @include tablet {
        padding: 0.75rem;
        width: 100%;
      }
    }
  }
  img {
    display: block;
    margin: 0 auto;
    width: 180px;
    @include tablet {
      left: 50%;
      max-width: initial;
      position: absolute;
      transform: translate(-50%, 0);
      top: 145px;
      width: 180px;
      z-index: 1;
    }
    @include desktop {
      left: 50%;
      position: absolute;
      top:0;
      transform: translate(-50%, 4%);
      width: 275px;
      z-index: 1;
    }
  }
  .is-size-3 {
    @include tablet {
      font-size: 1.5rem!important;
    }
    @include desktop {
      font-size: 2rem!important;
    }
  }
  &__left {
    border: 4px solid rgba(0, 0, 0, 1);
    border-radius: 10px;
    @include tablet {
      min-height: 500px;
    }
    @include desktop {
      margin-right: -30%;
      min-height: 460px;
      padding: 0.75rem 1rem 0.75rem;
      padding-right: 3rem;
    }
    h2 {
      @include desktop {
        text-align: center;
      }
    }
    ul {
      li {
        p {
          padding: 0;
          text-align: left!important;
        }
      }
    }
  }
  &__right {
    border: 4px solid #000;
    border-radius: 10px;
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom:0;
      min-height: 500px;
    }
    @include desktop {
      margin-left: -30%;
      min-height: 460px;
      padding: 0.75rem 1rem 0.75rem;
      padding-left: 3rem;
    }
    h2 {
      @include desktop {
        text-align: center;
      }
    }
    ul {
      li {
        p {
          padding: 0;
          text-align: left!important;
        }
      }
    }
  }
  &--huntertest {
    .title-padding {
      @include desktop {
        padding-bottom: 5rem;
      }
    }
  }
  &.comparison--v2 {
    align-items: center;
    h2 {
      text-align: center;
    }
    .comparison__left {
      @include desktop {
        margin-right: -24%;
        padding: 1rem;
      }
      @include widescreen{
        margin-right: -30%;
      }
    }
    .comparison__right {
      @include desktop {
        margin-left: -24%;
        padding: 1rem;
      }
      @include widescreen{
        margin-left: -30%;
      }
    }
    &.comparison--huntertest {
      .comparison__left {
        @include tablet {
          min-height: 450px;
        }
        @include desktop {
          min-height: 430px;
        }
      }
      .comparison__right {
        border: 4px solid #f15a29;
        @include tablet {
          min-height: 450px;
        }
        @include desktop {
          min-height: 430px;
        }
      }
    }
    &.comparison--hunterburn {
      .comparison__left {
        @include tablet {
          padding: 0;
          min-height: 460px;
        }
        @include desktop {
          padding: 1rem;
          min-height: 504px;
        }
      }
      .comparison__right {
        border: 4px solid #faa619;
        @include tablet {
          padding: 0;
          min-height: 460px;
        }
        @include desktop {
          padding: 1rem;
          min-height: 504px;
        }
      }
    }
    .is-paddingless {
      padding: 0.5rem!important;
    }
    img {
      display: block;
      margin: 0 auto;
      margin-top: -20%;
      width: 380px;
      @include tablet {
        left: 0%;
        margin-top: -15%;
        max-width: initial;
        position: relative;
        transform: none;
        top: 0;
        width: 480px;
        z-index: 1;
      }
      @include desktop {
        left: 50%;
        position: absolute;
        top:0;
        transform: translate(-50%, -39%);
        width: 275px;
        z-index: 1;
      }
    }
  }
  &.comparison--v3 {
    @include desktop {
      align-items: center;
    }
    .comparison__left {
      margin-right:0;
      padding:0;
      @include desktop {
        padding:1rem;
        min-height: 550px;
      }
      @include widescreen {
        min-height:480px;
      }
    }
    .comparison__right {
      margin-left:0;
      padding: 0rem;
      @include desktop {
        padding: 1rem;
        min-height: 550px;
      }
      @include widescreen {
        min-height:480px;
      }
    }
    &.comparison--huntertest {
      .comparison__left {
      }
      .comparison__right {
        border: 4px solid #f15a29;
      }
    }
    &.comparison--hunterburn {
      .comparison__left {
        @include desktop {
          min-height:630px;
        }
        @include widescreen {
          min-height: 530px;
        }
      }

      .comparison__right {
        border: 4px solid #faa619;
        @include desktop {
          min-height:630px;
        }
        @include widescreen {
          min-height: 530px;
        }
      }
    }
    h2 {
      text-align: center;
      @include desktop {
        line-height:1;
      }
      @include widescreen {
        line-height: 1.5;
      }
    }
    img {
      margin-top: -20%;
      width: 380px;
      @include tablet {
        left:0;
        margin-top: -17%;
        position: relative;
        transform: none;
        top:0;
        width: 480px;
      }
      @include desktop {
        left: 50%;
        margin-top:0;
        width: 310px;
      }
      @include widescreen {
        transform: translate(-50%, -4%);
      }
    }
  }
  &.comparison--v4 {

    ul {
      li {
        display: flex;
        .fa {
          margin-right: 10px;
        }
      }
    }
    align-items: center;
    h2 {
      text-align: center;
    }
    .comparison__left {
      min-height:auto;
      margin-right:0;
      @include tablet {
        height: 100%;
      }
      @include desktop {
        padding: 1rem;
      }
    }
    .comparison__right {
      min-height: auto;
      margin-left:0;
      @include tablet {
        height: 100%;
      }
      @include desktop {
        padding: 1rem;
      }
      @include widescreen{
      }
    }
    &.comparison--huntertest {
      .comparison__right {
        border: 4px solid #f15a29;
      }
    }
    &.comparison--hunterburn {
      .comparison__left {
      }
      .comparison__right {
        border: 4px solid #faa619;
        @include tablet {
          padding: 0;
          min-height: 460px;
        }
        @include desktop {
          padding: 1rem;
          min-height: 504px;
        }
      }
    }
    .is-paddingless {
      padding: 0.5rem!important;
    }
    img {
      display: block;
      margin: 0 auto;
      margin-top: -20%;
      width: 380px;
      @include tablet {
        left: 0%;
        margin-top: -15%;
        max-width: initial;
        position: relative;
        transform: none;
        top: 0;
        width: 480px;
        z-index: 1;
      }
      @include desktop {
        left: 50%;
        position: absolute;
        top:0;
        transform: translate(-50%, -39%);
        width: 275px;
        z-index: 1;
      }
    }
  }
}

.section--usps {
  img {
    max-width: 75px;
    @include desktop {
      max-width: 70px;
    }
  }
}

.section--highestdose {
  ul {
    display: flex;
    flex-flow: column;
    margin-left: 1rem;

    @include tablet {
      flex-flow: row wrap;
      margin-left: 0;
    }
    li {
      list-style: circle;
      padding:0;
      width: 100%;
      @include tablet {
        margin: 0;
        padding: 1rem 0rem 1rem 0;
        width: 50%;

      }
    }
  }
  img {
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    max-width: 75px;
    width: 75%;
    @include desktop {
      max-width: 75px;
      width: 50%;
    }
  }
}
.section--thesciencebehind {
  img {
    @include desktop {
      margin: 0 auto;
      width: 75%;
    }
  }
  .columns {
    align-items: center;
  }
}

.section--documentedbenefits {
  .is-flex {
    @include tablet {
      align-items: center;
    }
  }
  ul {
    li {
      margin: 2rem 0;
      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }
}
.section--social {
  .is-flex {
    flex-flow: column;
    @include tablet {
      align-items: center;
      flex-flow: row;
    }
  }
  .social__bottle {
    display: block;
    margin: 0 auto;
    margin-top:-40px;
    width:40%;
    @include tablet {
      left: 12%;
      position: absolute;
      top: 66px;
      width: 120px;
    }
    @include desktop {
      left: 20%;
      position: absolute;
      top: 5%;
      width: 180px;
    }
  }
  .social__content {
    padding: 1rem;
    @include tablet {
      padding: 1.5rem;
      width: 100%;
    }
    @include desktop {
      padding: 2rem;
      padding-left: 8rem;
      width: 50%;
    }
  }
  &-v2 {
    background: $black;
    color: $white;
    .social__content {
      padding: 1rem;
      @include tablet {
        padding: 2rem;
      }
      @include desktop {
        padding: 2rem;
        padding-left: 14rem;
        width: 95%;
      }
    }
    .social__bottle {
      display: block;
      margin: 0 auto;
      margin-top:-40px;
      width:40%;
      @include tablet {
        display: none;
      }
      @include desktop {
        display: block;
        left: 0;
        position: absolute;
        top: -5px;
        width: 208px;
      }
    }
  }
}


.scroll-mobile {
  overflow-x: scroll;
  img {
    min-width: 800px;
    @include tablet {
      min-width: initial;
    }
  }
  @include tablet {
    overflow-x:auto;
  }
}

.introduction {

  .testimonial__person {
    padding: 1rem;
  }
  img {
    display: block;
    margin: 0 auto;
    max-height: 350px;
  }
  .product-test, .product-burn, .product-focus {
    align-items:center;
    background: white;
    border-radius: 6px;
    display: flex;
    @include desktop {
      min-height: 500px;
    }
    .testimonial__quote {
      text-align: center;
      @include tablet {

      }
      p {
        text-align: left;
      }
      @include tablet {
        align-items: flex-start;
        display: flex;
        flex-flow: column;
        justify-content: center;
        min-height: 580px;
        text-align: left;
      }
    }
  }
  .product-focus {
    background: url(../images/cms/focus-white-bg.jpg) no-repeat;
    background: url(../images/cms/focus-white-bg.webp) no-repeat;
    background-size: 270%;
    //color: white;
    @include tablet {
      background: url(../images/cms/focus-white-bg.jpg) no-repeat;
      background: url(../images/cms/focus-white-bg.webp) no-repeat;
      background-size: cover;
      //color: white;
    }
    .testimonial__quote {
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      margin: 0;
      margin-top: 280px;
      padding: 1rem;
      @include tablet {
        margin-top:0;
        padding: 2rem;
      }
      @include desktop {
        background: none;
      }
    }
  }
  .product-test {
    background: url(../images/cms/test-white-bg.jpg) no-repeat;
    background: url(../images/cms/test-white-bg.webp) no-repeat;
    background-size: 270%;
    //color: white;
    @include tablet {
      background: url(../images/cms/test-white-bg.jpg) no-repeat;
      background: url(../images/cms/test-white-bg.webp) no-repeat;
      background-size: cover;
      min-height: 574px;
    }

    .testimonial__quote {
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      margin: 0;
      margin-top: 280px;
      padding: 1rem;
      @include tablet {
        border-radius: 0 6px 6px 0;
        margin-top:0;
        padding: 2rem;
      }
      @include desktop {
        background: none;
      }
    }
  }
  .product-burn {
    background: url(../images/cms/burn-white-bg.jpg) no-repeat;
    background: url(../images/cms/burn-white-bg.webp) no-repeat;
    background-size: 270%;
    //color: white;
    @include tablet {
      background-size: cover;
      min-height: 574px;
    }
    @include desktop {
      background: url(../images/cms/burn-white-bg.jpg) no-repeat;
      background: url(../images/cms/burn-white-bg.webp) no-repeat;
      background-size:cover;
    }
    .testimonial__quote {
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      margin: 0;
      margin-top: 280px;
      padding: 1rem;
      @include tablet {
        border-radius: 0 0 6px 6px;
        margin-top:0;
        padding: 2rem;
      }
      @include desktop {
        background: none;
      }
    }
  }
  .product-test, .product-focus, .product-burn {
    flex-flow: row-reverse;
  }
  .flex-center {
    align-items: center;
  }
  .is-size-5 {
    margin-bottom: 1rem;
  }
}


.wesaw-section {
    background: url(../images/cms/man-kneeling-bg2.jpg) no-repeat;
    background-size: 190%;
    background-position: 90% -10%;
    @include tablet {
      background-size: 150%;
      background-position: 100% -58%;
    }
    @include desktop {
      background-size: 105%;
      background-position: -220% 32%;
    }
    @include widescreen {
      background: url(../images/cms/man-kneeling-bg2.jpg) no-repeat, url(../images/cms/man-kneeling-bg1.jpg) no-repeat;
      //background-size: 60%, 86%;
      //background-position: 104% 36%, -250% 16%;
      background-size: 70%, 75%;
      background-position: 111% 36%, -215% 16%;
    }
    @media all and (min-width: 1160px) {
      background-size: 90%, 90%;
      background-position: 160% 36%, -3% 16%;
    }

    @media all and (min-width: 1400px) {
      background-size: 82%, 82%;
      background-position: 113% 36%, -223% 16%;
    }
    @media all and (min-width: 1700px) {
      background-size: 60%, 60%;
      background-position: 100% 36%, -80% 16%;
    }
    .wesaw-section__mobileimg {
      min-height: 400px;
      @include tablet {
        min-height: 500px;
      }
    }
    .wesaw-section__content {
      background: #ebebeb;
    color: #222222;
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 1rem;
      @include tablet {
        padding: 2rem;
      }
      @include desktop {
        min-height: 750px;
        padding: 4rem 2rem;
      }
    }
}

.premiumingredients-section {
  background-color: #0a0a0a;
  background-size: 180%;
  background-position: 0% 0%;
  padding-top: 0;

  @include tablet {
    //background: url(../images/cms/homepage-hero-hunter-withoutwave.jpg) no-repeat;
    background-position: 60% 0%;
    background-size: 120%;
  }

  @include desktop {
    background: url(../images/cms/homepage-hero-hunter-withoutwave.jpg) no-repeat;
    min-height: 63vh;
    background-position: 60% 15%;
    background-size: 128%;
    padding-top: 0;
  }
  @include widescreen {
    background: url(../images/cms/homepage-hero-hunter-withoutwave.jpg) no-repeat,  url(../images/cms/homepage-hero-hunter-withoutwave.jpg) no-repeat;
    background-position: 200% 15%, 100%;
    background-size: 104%, 100%;
    padding-top: 0;
  }
  .premiumingredients-section__mobileimg {
    min-height: 400px;
    @include tablet {
      min-height: 500px;
    }
    img {
      @include tablet {
        display: block;
        margin: 0 auto;
        max-height: 500px;
      }
    }
  }

  .premiumingredients-section__content {
    background: #ebebeb;
  color: #222222;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 1.5rem;
    @include tablet {
      padding: 2rem;
    }
    @include desktop {
      min-height: 750px;
      padding: 4rem 2rem;
    }
  }
  .hero__product {
    margin: 0 auto;
    position: absolute;
    transform: rotate(24deg);
    lefT: 0;
    max-height:380px;
    top:2%;
    width: 300px;
    @include tablet {
      max-height:initial;
      width: 420px;
    }

    @include desktop {
      left: unset;
      margin: auto;
      max-height: 380px;
      transform: none;
      width: auto;
    }
  }
  .hunter-test-bottle {
    left: -18%;
    @include tablet {
      left: -10%;
    }
    @include desktop {
      left: -180px;
    top: 20%;
    }
    @include widescreen {
      left: -180px;
      top: 28%;
    }
  }

  .hunter-burn-bottle {
    left: 50%;
    transform: translate(-50%, 0) rotate(24deg);
    @include tablet {
    }
    @include desktop {
      //right: -250px;
      left: 140px;
      top: -1%;
      transform: none;
    }
    @include widescreen {
      left: 115px;
      top: 8%;
    }
  }

  .hunter-focus-bottle {
    left: 78%;
    margin-top: 63px;
    transform: rotate(24deg) translate(-50%, 0);
    @include tablet {
      left: 80%;
      margin-top:0;
      top:10%;
    }
    @include desktop {
      left: -7px;
      margin-top: auto;
      transform: none;
      top: 17%;
    }
    @include widescreen {
      left: -7px;
      margin-top: auto;
      transform: none;
      top: 28%;
    }
  }

}

.designedtobecombined-section {
    background: url(../images/cms/people-running.jpg) no-repeat;
    background-size: 180%;
    background-position: 70% 0%;
    @include tablet {
      background-position: 72% -49%;
    }

    @include desktop {
      background-position: -206% 6%;
      background-size: 111%;
    }
    @include widescreen {
      //background: url(https://as2.ftcdn.net/v2/jpg/03/64/68/83/1000_F_364688332_IJcztMgL8HDSU9eAEyfSLYyagSjjOdfW.jpg) no-repeat, url(https://as2.ftcdn.net/v2/jpg/03/64/68/83/1000_F_364688332_IJcztMgL8HDSU9eAEyfSLYyagSjjOdfW.jpg) no-repeat;
      background: url(../images/cms/people-running.jpg) no-repeat, url(../images/cms/people-running.jpg) no-repeat;
      background-size: 63%, 99%;
      background-position: 114% 17%, -181% 32%;
    }
      @media all and (min-width: 1400px) {
        background-size: 80%, 80%;
        background-position: 170% 20%, 0 0;
      }


    .designedtobecombined-section__content {
      background: #ebebeb;
    color: #222222;
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 1rem;
      @include tablet {
        padding: 2rem;
      }
      @include desktop {
        min-height: 750px;
        padding: 4rem 2rem;
      }
    }

    .designedtobecombined-section__mobileimg {
      min-height: 400px;
      @include tablet {
        min-height: 500px;
      }
    }

}
.anameyoucantrust {
  background: #f9f9f9;
  .container {
    padding-top:0;
    @include desktop {
      padding-top: 0rem;
    }
    @include widescreen {
      padding-top: 4rem;
    }
  }

  .card {
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    display: flex;
    flex-flow: column;
    padding: 0;
    @include desktop {
      flex-flow: row;
    }
    .card-image {
      background: url(../images/cms/man-in-lab.jpg) no-repeat;
      background: url(../images/cms/man-in-lab.webp) no-repeat;
      background-position:center;
      background-size: cover;
      border-radius: 6px 6px 0 0;
      height: 400px;
      width: 100%;
      @include tablet {
        background: url(../images/cms/man-in-lab.jpg) no-repeat;
        background: url(../images/cms/man-in-lab.webp) no-repeat;
        background-position:center;
        background-size: cover;
        border-radius: 6px 0 0 6px;
      }
      @include desktop {
        background-position: 50%;
        background-size: 240%;
        min-height: 600px;
        width: 40%;
      }
      img {
        display: none;
      }
    }
    .card-content {
      padding: 1rem;
      width: 100%;
      @include tablet {
        padding: 2rem;
      }
      @include desktop {
        width: 60%;
      }
    }
  }

  .icons {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;
    z-index: 5;
    @include tablet {
      flex-flow: row;
    }
    img {
      border-radius: 6px 6px 0 0;
      display: block;
      float: right;
      margin: 0;
      max-width: 140px;
      padding: 0.25rem;
      @include tablet {
        max-width: 120px;
      }
      @include desktop {
        max-width: 125px;
      }
    }
    .icon__produced {
      margin-bottom: -20px;
      @include desktop {
        margin-bottom:0;
      }
    }
    .icons__bottom {

    }
  }
  .wesaw-section__content {
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}

.philosophy {
  background-color: #f9f9f9;
  .container {
    padding-top:0;
    padding-bottom: 0;
    @include desktop {
      padding-bottom: 2rem;
    }
    @include widescreen {
      padding-bottom: 0rem;
      padding-top: 0rem;
    }
  }

  .card {
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    display: flex;
    flex-flow: column;
    padding: 0;
    @include desktop {
      flex-flow: row-reverse;
    }
    .card-image {
      background: url(../images/burn/manonmountain.jpg) no-repeat;
      background-size: cover;
      border-radius: 6px 6px 0 0;
      height: 400px;
      width: 100%;
      @include desktop {
        background: url(../images/burn/manonmountain.jpg) no-repeat;
        background-size: cover;
        border-radius: 0 6px 6px 0;
        height: 100%;
        min-height: 600px;
        width: 50%;
      }
      @include desktop {
        min-height: 600px;
        width: 40%;
      }
      img {
        display: none;
      }
    }
    .card-content {
      padding: 1rem;
      width: 100%;
      @include tablet {
        padding: 2rem;
      }
      @include desktop {
        width: 60%;
      }
    }
  }


}

/*.tribe {
    background: url(https://as2.ftcdn.net/v2/jpg/03/64/68/83/1000_F_364688332_IJcztMgL8HDSU9eAEyfSLYyagSjjOdfW.jpg) no-repeat;
    background-size: 140%;
    background-position: 100% 0%;
    @include desktop {
        background-position: -20% 0%;
    }

    @include widescreen {
      background: url(https://as2.ftcdn.net/v2/jpg/03/64/68/83/1000_F_364688332_IJcztMgL8HDSU9eAEyfSLYyagSjjOdfW.jpg) no-repeat, url(https://as2.ftcdn.net/v2/jpg/03/64/68/83/1000_F_364688332_IJcztMgL8HDSU9eAEyfSLYyagSjjOdfW.jpg) no-repeat;
      background-size: 78%, 72%;
      background-position: 190%, -183% 101%;
    }
    .wesaw-section__content {
      background: #ebebeb;
    color: #222222;
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 1.5rem;
      @include tablet {
        padding: 2rem;
      }
      @include desktop {
        min-height: 650px;
        padding: 4rem 2rem;
      }
    }

}*/
.tribe {
  background: #ebebeb;
  img {
    display: block;
    margin: 0 auto;
    max-width: 400px;
  }
  &--v2 {
    h3 {
      @include desktop {
        margin-bottom: 0rem;
      }
    }
    img {
      max-width: 120px;
      @include tablet {
        margin-bottom: 1rem;
        max-width: 100%;
      }
      @include desktop {
        max-width:180px;
      }
    }
    .card {
      display: flex;
      flex-flow: column;
      .card-content {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        flex-grow: 4;
        text-align: center;
        p {
          &:last-child {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
.testimonials-section {
  padding-bottom: 3rem;
}
.usps-section {
  background: black;
  color: white;
  padding: 0 0.5rem;
  @include desktop {
      padding: 3rem 0;
  }
  .key-point-with-icon {
    width: 25%;
  }
  .usps-section__usp {
    display: flex;
    img {
      margin-right: 10px;
      max-width: 50px;
    }
    &--column {
      flex-flow: column;
    }
  }
}

.demandthebest {
  background: #f9f9f9;
  /*img {
    border-radius:6px 6px 0 0;
    display: block;
    @include tablet {

    }
  }*/
  .container {
    padding-bottom: 0;
    @include desktop {
      padding-bottom: 2rem;
    }
    @include widescreen {
      padding-bottom: 4rem;
    }
  }
  .card {
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    display: flex;
    flex-flow: column;
    padding: 0;
    @include desktop {
      flex-flow: row;
    }
    .card-image {
      background: url(../images/cms/womanandmanlaughing.jpg) no-repeat;
      background-size: cover;
      border-radius: 6px 6px 0 0;
      height: 400px;
      width: 100%;
      @include tablet {
        background: url(../images/cms/womanandmanlaughing.jpg) no-repeat;
        background-size: 110%;
        background-position: 25% 17%;
        border-radius: 0 6px 6px 0;
        height: 400px;
      }
      @include desktop {
        border-radius: 6px 0 0 6px;
        height: 100%;
        width: 40%;
      }
      img {
        display: none;
      }
    }
    .card-content {
      padding: 1rem;
      width: 100%;
      @include tablet {
        padding: 2rem;
      }
      @include desktop {
        padding: 2rem;
        width: 60%;
      }
    }
  }
}

// Flating Telegram Icon
#telegram {
    display: none;
    position: fixed;
    bottom: 120px;
    left: 20px;
    z-index: 99;
    font-size: 1rem;
    border: none;
    outline: none;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #333;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    @include desktop {
      bottom: 70px;
      left: 26px;
    }
}
