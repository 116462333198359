.cms-hunter-birthday {
  .card-wrapper {
    margin: 0 auto;
  }

  .top-content {
    background-color: $light-black;
    border-radius: 8px;
    padding: 10px;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    @include desktop {
      padding: 20px;
      margin-top: 0;

      img {
        margin-right: 20px
      }
    }
  }

  .card {
    padding: 2.5rem;
    border-radius: 4px;

    &.step-card {
      padding: 15px;
      @include tablet {
        padding: 20px;
      }

      h4 {
        margin-bottom: 5px;
      }
    }

    img {
      margin: 10px auto;

      @include tablet {
        margin: 20px auto;
      }
    }
  }

  #products {
    padding: 0px;

    @include tablet {
      padding: inherit;
    }

    .card {
      background: linear-gradient(to bottom, rgba(0,0,0,2),rgba(255,255,255,0),rgba(255,255,255,0));
      padding: 0;
      box-shadow: none;

      .product__image-wrapper {
        background: linear-gradient(to bottom, rgba(0,0,0,2),rgba(255,255,255,0),rgba(255,255,255,0));
        border: 1px solid #6F6F6F;
        border-radius: 8px;
        padding-top: 0px;

        @include tablet {
          padding-top: 130px;
          margin-top: 0px;
          border: none;
        }
      }

      .image-wrapper {
        padding: 0px;
        border: 1px solid #6F6F6F;
        background-position: center 100px;
        background-size: auto 75%;
        background-repeat: no-repeat;
        border-radius: 8px;
        height: 350px;

        &.hunter_test_1_month {
          background-image: url(../images/test/test-bundle-mobile-1.png);
        }

        &.hunter_burn_1_month {
            background-image: url(../images/burn/burn-bundle-mobile-1.png);
        }

        &.hunter_focus_1_month {
            background-image: url(../images/focus/png/focus-bundle-mobile-1.png);
        }

        @include tablet{
          padding: 10px;
          border: none;
          background-position: center 125px;
          height: 375px;

          &.hunter_test_1_month {
              background-image: url(../images/test/bundle-1@2x.png);
          }

          &.hunter_burn_1_month {
              background-image: url(../images/burn/bundle-1@2x.png);
          }

          &.hunter_focus_1_month {
              background-image: url(../images/focus/png/bundle-1@2x.png);
          }
        }
      }


      h2.sm-text.light {
        padding: 0;
        font-size: 1.25rem;
        line-height: 1.5rem;
        margin-bottom: 15px;

        @include tablet {
          font-size: 1.5rem;
          line-height: 2rem;

        }
      }

      .image-top {
        text-align: center;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:after {
          content: '';
          width: 90%;
          max-width: 240px;
          position: relative;
          border-bottom: 1px dashed white;
          display: block;
          margin: 0 auto;

          @include tablet {
            display: block;
          }
        }
      }

      .image-middle {
        margin-top: 185px;
        p {
          font-size: 1rem;
          line-height: 1rem;
          margin-bottom: 5px;
        }

        @include tablet {
          margin-top: 210px;
        }
      }

      .product-info__order-info {
        position: relative;
        height: 100px;
        bottom: auto;
        left: auto;
        right: auto;

        .product__price {
          margin-top: 20px;
        }

        label {
          font-size: 1rem;
          font-weight: bold;
          margin-right: 10px;
        }

        select {
          width: 55px;
          font-size: 1rem;
          font-weight: bold;
          border-radius: 4px;
        }
      }

      img{
        margin: 0;
      }
    }
  }
}

.birthday-dark {
  background-color: #000000;
}

.birthday-gradient {
  background: linear-gradient(to bottom, #000 0%, #0DB57B 100%, #1D7137 100%);
  opacity: 1;
  .centered {
    text-align: center;
  }
}

.birthday-gradient-1 {
  // background: linear-gradient(to bottom, rgb(0,0,0), rgb(8, 145, 179));
  background: linear-gradient(to bottom, #1D7137 100%, #0DB57B 100%);
}
