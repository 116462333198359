.test-section--1 {

  .main-img {
    border-radius: 10px;
  }

  @include tablet {
    background-position: bottom;
  }

  h2 {
    margin-bottom: 30px;

    @include tablet {
      margin-bottom: 50px;
    }
  }
  .main-img {
    img {
      border-radius: 10px;
    }
  }
  .is-flex {
    align-items: center;
  }
  /**flex arrange on mobile**/
  .columns {
    .column {
      &:first-child {
        order:1;
        @include tablet{
          order: initial;
        }
      }
      &:nth-child(2){
        order:2;
        @include tablet{
          order: initial;
        }
      }
      &:nth-child(3) {
        order:3;
        @include tablet{
          order: initial;
        }
      }
      &:nth-child(4){
        order:5;
        @include tablet{
          order: initial;
        }
      }
      &:nth-child(5){
        order:4;
        @include tablet{
          order: initial;
        }
      }
    }
  }
}

.test-section--2 {
  //background-image: url(../images/cms/hunter-test-2.jpg);
  .main-img {
    img {
      border-radius: 10px;
    }
  }

}

.test-section--4 {
  height: auto;
  .container--wide {
    @include tablet {
      height:715px;
    }
    @include desktop {
      height: auto;
    }
  }

  .owl-carousel .owl-item img {
    margin: 0px auto 0;
    width: 25%;
  }
}
.test-section--5 {


  .main-img {
      border-radius: 10px;
  }
}

.test-section--6 {
  .card {
    .block {
      margin: 10px 0
    }

    h3 {
      margin: 20px 0 0;
    }
  }
  .btn--bordered-black {
    background: $white;
    &:hover {
      background: $black;
    }
  }
}

.test-section--7 {
  background: black url(../images/cms/hexagons-test-background-2.png) center top no-repeat;
}

.test-section--9 {
  img {
    max-width: 400px;
    margin: auto;
    width: 100%;
  }
}

.test-section--11 {
  background: #f9f9f9;

  @include tablet {
    //background-image: url(../images/cms/hunter-test-9.jpg);
  //  padding: 0 0 350px;
  }
  @include desktop {
    //padding-top: 280px;
    z-index: 9;
  }
  .is-flex {
    align-items: center;
  }
  .main-img {
    img {
      border-radius: 10px;
    }
    border-radius: 10px;
  }
}

.test-section--12 {
  .is-flex {
    align-items: center;
  }
  .main-img {
      border-radius: 10px;
  }
  .columns {
    .column {
      &:first-child {
        order:2;
        @include tablet{
          order: initial;
        }
      }
      &:nth-child(2){
        order:1;
        @include tablet{
          order: initial;
        }
      }
    }
  }
  .container {
    padding-bottom:0;
  }
}

.test-gradient {
  //background-image: linear-gradient(to bottom, #060301, #431b0f);
  background: #f9f9f9;
}

.test-gradient-1 {
  background: linear-gradient(to bottom, #000, $hunter-orange);
}

.test-gradient-2 {
  background: linear-gradient(to bottom, $light-black, $hunter-orange);
}

.test-table {
  background: #fff;
  min-width: 370px;

  h2 {
    line-height: 1rem;
    margin: 0;
    padding: 0.5rem;
    .is-size-6 {
      font-weight: 600;
    }
  }
  .is-borderless {
    border: none!important;
  }
  .border-bottom {
    border-bottom: 1px solid #000!important;
  }
  .footnotes {
    padding: 0.5rem;
    p {
      line-height: 1rem;
    }
  }
}
.orange-wave {
  background-image: url(../images/test/wave-background-orange@2x.png);
  background-image: url(../images/test/wave-background-orange@2x.webp);
  background-size: 2200px 1035px;
  background-position: 0% 86%;
  overflow: hidden;
  @include tablet {
    background-position: 0% 38%;
  }
  &.ingredients-page {
    background-position: 61% -32%;
    @include tablet {
      background-position: 61% 52%;
    }
    .white-gradient {
      background: rgba(255, 255, 255, 0.7);
    }
  }
}


.highestdose {
  h2 {
    position: relative;
    z-index:1;
  }
  h3 {
    line-height: 1.5;
    padding: 0.5rem;
    @include tablet {
      margin-bottom:0;
      padding: 0.5rem;
      //position: absolute;
    }
    &.bordered {
      background: rgba(255,255,255,0.75);
      border: 4px solid #827d7f;
      border-radius: 16px 16px;
      @include tablet {
        border: 5px solid #f7a48b;
      }
    }
    &.border-bottom {
      background: rgba(255,255,255,0.75);
      border-bottom: 4px solid #827d7f;
      @include tablet {
        border-bottom: 5px solid #827d7f;
      }
    }
    &.background-grey {
      background: rgba(237,237,237,0.88);
      border-radius: 16px 16px;
    }
  }

  .highestdose--bg {
    align-items: center;
    background-image: url(../images/test/molecule-vertical.png);
    background-image: url(../images/test/molecule-vertical.svg);
    background-repeat:no-repeat;
    background-position: 35%;
    display: flex;
    flex-flow:column;
    justify-content: center;
    margin-bottom: -15%;
    margin-top:-10%;
    min-height: 600px;
    @include tablet {
      align-content:space-between;
      background-image: url(../images/test/molecule-horizontal.png);
      background-image: url(../images/test/molecule-horizontal.svg);
      background-repeat:no-repeat;
      background-position: center;
      background-size: cover;
      //flex-flow: row wrap;
      justify-content: space-around;
      margin-bottom: 0;
      margin-top:0;
      min-height: 450px;
    }
    @include desktop {
      margin-bottom: -10%;
      margin-top: -5%;
      min-height:633px;
    }
    @include widescreen {
      margin-top: 0;
    }
    h3 {
      @include desktop {
        min-width: 660px;
      }
      &.point-1 {
        @include tablet {
          right:10px;
        //  width: 320px;
        }
        @include desktop {
          right: 5%;
          top:10%;
        }
        @include widescreen {
          right: 10%;
        }
      }
      &.point-2 {
        @include tablet {
          left:10px;
          top: 23%;
        //  width: 320px;
        }
        @include desktop {
          left: -10%;
          top:23%;
        }
        @include widescreen {
          left: -32%;
          top: 24%;
        }
      }
      &.point-3 {
        @include tablet {
          right:10px;
          top: 42%;
        //  width: 260px;
        }
        @include desktop {
          right: -20%;
          top:42%
        }
        @include desktop {
          right: -15%;
          top: 40%;
        }
      }
      &.point-4 {
        @include tablet {
          left:8%;
          top: 63%;
        //  width: 260px;
        }
        @include desktop {
          left: -12%;
          top: 64%;
          //width: 260px;
        }
        @include widescreen {
          left: -8%;
          top: 68%;
          //width: 260px;
        }

      }
      &.point-5 {
        @include tablet {
          bottom:3%;
          right:10px;
        //  width: 260px;
        }
        @include desktop {
          bottom: 7%;
          right: -19%;
        }
      }
    }
  }
}

.pills {
  h2 {
    position: relative;
    z-index:1;
  }
  h3 {
    line-height: 1.5;
    padding: 0.5rem;
    @include tablet {
      margin-bottom:0;
      padding: 0.5rem;
      //position: absolute;
    }
    @include desktop {
      min-width: 540px;
    }
    &.bordered {
      background: rgba(255,255,255,0.75);
      border: 4px solid #827d7f;
      border-radius: 16px 16px;
      @include tablet {
        border: 5px solid #827d7f;
      }
    }
    &.border-bottom {
      background: rgba(255,255,255,0.75);
      border-bottom: 4px solid #827d7f;
      @include tablet {
        border-bottom: 5px solid #827d7f;
      }
    }
    &.background-grey {
      background: rgba(237,237,237,0.88);
      border-radius: 16px 16px;
    }
  }

  .pills--bg1 {
    align-items: center;
    background-image: url(../images/test/hunter-test-pills-bg.png);
    background-repeat:no-repeat;
    background-position: 35%;
    display: flex;
    flex-flow:column;
    justify-content: center;
    margin-bottom: -15%;
    margin-top:-10%;
    min-height: 600px;
    @include tablet {
      align-content:space-between;
      background-image: url(../images/test/hunter-test-pills-bg.png);
      background-repeat:no-repeat;
      background-position: center;
      background-size: cover;
      //flex-flow: row wrap;
      justify-content: space-around;
      margin-bottom: 0;
      margin-top:0;
      min-height: 450px;
    }
    @include desktop {
      margin-bottom: -10%;
      margin-top: -5%;
      min-height:633px;
      background-size: 65%;
      background-position: bottom;
    }
    @include widescreen {
      margin-top: 0;
    }
    h3 {
      &.point-1 {
        @include tablet {
          right:10px;
        //  width: 320px;
        }
        @include desktop {
          right: 5%;
          top:10%;
        }
        @include widescreen {
          right: 10%;
        }
      }
      &.point-2 {
        @include tablet {
          left:10px;
          top: 23%;
        //  width: 320px;
        }
        @include desktop {
          left: -10%;
          top:23%;
        }
        @include widescreen {
          left: -32%;
          top: 24%;
        }
      }
      &.point-3 {
        @include tablet {
          right:10px;
          top: 42%;
        //  width: 260px;
        }
        @include desktop {
          right: -20%;
          top:42%
        }
        @include desktop {
          right: -15%;
          top: 40%;
        }
      }
      &.point-4 {
        @include tablet {
          left:8%;
          top: 63%;
          //width: 260px;
        }
        @include desktop {
          left: -12%;
          top: 64%;
          //width: 260px;
        }
        @include widescreen {
          left: -8%;
          top: 68%;
          //width: 260px;
        }

      }
      &.point-5 {
        @include tablet {
          bottom:3%;
          right:10px;
        //  width: 260px;
        }
        @include desktop {
          bottom: 7%;
          right: -19%;
        }
      }
    }
  }
  .pills--bg2 {
    align-items: center;
    background-image: url(../images/test/hunter-test-multi-pills-bg.png);
    background-repeat:no-repeat;
    background-position: 35%;
    display: flex;
    flex-flow:column;
    justify-content: center;
    margin-bottom: -15%;
    margin-top:-10%;
    min-height: 600px;
    @include tablet {
      align-content:space-between;
      background-image: url(../images/test/hunter-test-multi-pills-bg.png);
      background-repeat:no-repeat;
      background-position: center;
      background-size: cover;
      //flex-flow: row wrap;
      justify-content: space-around;
      margin-bottom: 0;
      margin-top:0;
      min-height: 450px;
    }
    @include desktop {
      margin-bottom: -10%;
      margin-top: -5%;
      min-height:633px;
      background-size: 65%;
      background-position: bottom;
    }
    @include widescreen {
      margin-top: 0;
    }
    h3 {
      &.point-1 {
        @include tablet {
          right:10px;
          width: 320px;
        }
        @include desktop {
          right: 5%;
          top:10%;
        }
        @include widescreen {
          right: 10%;
        }
      }
      &.point-2 {
        @include tablet {
          left:10px;
          top: 23%;
          width: 320px;
        }
        @include desktop {
          left: -10%;
          top:23%;
        }
        @include widescreen {
          left: -32%;
          top: 24%;
        }
      }
      &.point-3 {
        @include tablet {
          right:10px;
          top: 42%;
          width: 260px;
        }
        @include desktop {
          right: -20%;
          top:42%
        }
        @include desktop {
          right: -15%;
          top: 40%;
        }
      }
      &.point-4 {
        @include tablet {
          left:8%;
          top: 63%;
          width: 260px;
        }
        @include desktop {
          left: -12%;
          top: 64%;
          width: 260px;
        }
        @include widescreen {
          left: -8%;
          top: 68%;
          width: 260px;
        }

      }
      &.point-5 {
        @include tablet {
          bottom:3%;
          right:10px;
          width: 260px;
        }
        @include desktop {
          bottom: 7%;
          right: -19%;
        }
      }
    }
  }
}

.section--nameyoucantrust {
  .icons {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;
    z-index: 5;
    @include tablet {
      flex-flow: row;
    }
    img {
      border-radius: 6px 6px 0 0;
      display: block;
      float: right;
      margin: 0;
      max-width: 140px;
      padding: 0.25rem;
      @include tablet {
        max-width: 120px;
      }
      @include desktop {
        max-width: 125px;
      }
    }
    .icon__produced {
      margin-bottom: -20px;
      @include desktop {
        margin-bottom:0;
      }
    }
    .icons__bottom {

    }
  }
}

.moreofwhatyouneed {
  background: #ebebeb;
  .container {
    padding-bottom: 0;
    @include desktop {
      padding-bottom: 2rem;
    }
    @include widescreen {
      padding-bottom: 4rem;
    }
  }
  .card {
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    display: flex;
    flex-flow: column;
    padding: 0;
    @include desktop {
      flex-flow: row;
    }
    .card-image {
      background: url(../images/test/men-on-rocks.jpg) no-repeat;
      background-size: cover;
      border-radius: 6px 6px 0 0;
      height: 400px;
      width: 100%;
      @include tablet {
        background: url(../images/test/men-on-rocks.jpg) no-repeat;
        background-size: 110%;
        background-position: 25% 17%;
        border-radius: 0 6px 6px 0;
        height: 400px;
      }
      @include desktop {
        background-size: cover;
        background-position: 80% 0%;
        border-radius: 6px 0 0 6px;
        height: 100%;
        min-height: 600px;
        width: 40%;
      }
      img {
        display: none;
      }
    }
    .card-content {
      padding: 1rem;
      width: 100%;
      @include tablet {
        padding: 2rem;
      }
      @include desktop {
        padding: 2rem;
        width: 60%;
      }
    }
  }
}

.dosedforpremiumperformance {
  background: #ebebeb;
  .container {
    padding-bottom: 0;
    @include desktop {
      padding-top:0;
      padding-bottom: 2rem;
    }
    @include widescreen {
      padding-bottom: 4rem;
    }
  }
  .card {
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    display: flex;
    flex-flow: column-reverse;
    padding: 0;
    @include desktop {
      flex-flow: row;
    }
    .card-image {
      background: url(../images/test/couple-laughing.jpg) no-repeat;
      background-size: cover;
      border-radius: 6px 6px 0 0;
      height: 400px;
      width: 100%;
      @include tablet {
        background: url(../images/test/couple-laughing.jpg) no-repeat;
        background-size: 110%;
        background-position: 25% 17%;
        border-radius: 0 6px 6px 0;
        height: 400px;
      }
      @include desktop {
        background-position: 72% 17%;
        background-size: cover;
        border-radius: 6px 0 0 6px;
        height: 100%;
        min-height: 600px;
        width: 40%;
      }
      img {
        display: none;
      }
    }
    .card-content {
      padding: 1rem;
      width: 100%;
      @include tablet {
        padding: 2rem;
      }
      @include desktop {
        padding: 2rem;
        width: 60%;
      }
    }
  }
}


.reversetestosteronedepletetion {
  background: #ebebeb;
  .container {
    @include desktop {
      padding-top:0;
      padding-bottom: 2rem;
    }
    @include widescreen {
      padding-bottom: 4rem;
    }
  }
  .card {
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    display: flex;
    flex-flow: column;
    padding: 0;
    @include desktop {
      flex-flow: row;
    }
    .card-image {
      background: url(../images/test/guyonmountain-v2.jpg) no-repeat;
      background-size: cover;
      border-radius: 6px 6px 0 0;
      height: 400px;
      width: 100%;
      @include tablet {
        background: url(../images/test/guyonmountain-v2.jpg) no-repeat;
        background-size: 110%;
        background-position: 25% 17%;
        border-radius: 0 6px 6px 0;
        height: 400px;
      }
      @include desktop {
        border-radius: 6px 0 0 6px;
        height: 100%;
        min-height: 600px;
        width: 40%;
      }
      img {
        display: none;
      }
    }
    .card-content {
      padding: 1rem;
      width: 100%;
      @include tablet {
        padding: 2rem;
      }
      @include desktop {
        padding: 2rem;
        width: 60%;
      }
    }
  }
}

.huntertest-testimonials {
  .testimonials {
    margin-bottom: 1rem;

    .testimonial {
      border-radius: 0;
      min-height: auto;
      opacity:1;
      background: #ffffff;
      display: flex;
      flex-flow: column;
      height: 100%;
      justify-content: center;
      padding: 1.25rem;
      height: 100%;
      margin: 0;
      width: 320px;
      .is-size-5 {
        margin-bottom: 1rem;
      }
      .testimonial__quote {
        margin-bottom:0;
        padding:0;
      }
      .is-size-5 {
        align-items: center;
        justify-content: center;
        display: flex;
        min-height: 56px;
      }
      @include desktop {
        min-height: 460px;
        width: 480px;
      }
    }

    img {
      border-radius: 50%;
      margin: 20px auto;
      max-width: 100px;
      width: 80%;
    }
  }
  .testimonials__navigation {
    display:flex;
    height: 40px;
    justify-content: flex-end;
    position: relative;
    .carousel__prev, .carousel__prev--testimonials {
      background: black;
      border-radius:0;
      color: white;
      bottom:0;
      font-size:22px;
      height: 40px;
      left:0;
      margin:0;
      margin-right:10px;
      position: relative;
      right: 0;
      text-align:center;
      top: initial;
      width: 40px;
    }
    .carousel__next, .carousel__next--testimonials {
      background: black;
      border-radius:0;
      color: white;
      font-size: 22px;
      height: 40px;
      bottom:0;
      margin:0;
      margin-right: 10px;
      right: 0;
      position: relative;
      text-align:center;
      top: initial;
      width: 40px;
    }
  }
  .owl-stage {
    align-items: stretch;
    display:flex;
  }
  .btn--bordered-black {
    background: $white;
    &:hover {
      background: $black;
      color: $white;
    }
  }
}
