.messages {
  margin-top: 55px;

  @include tablet {
    margin-top: 0;
  }
}

.message.notice {
    padding: 10px 10px;
    padding-left: 40px;
    font-size: 0.9rem;
}

.page.messages {

  @include tablet {
    margin: 0;
    padding: 0;

    .message {
      padding: 10px !important;

      div {
        font-size: 16px;
        font-weight: 700;
        padding-left: 25px;

        &:before {
          top: 22px
        }
      }
    }
  }

}

.contact-index-index {
  .page.messages {
    margin-top: 100px;

    @include tablet {
      margin-top: 55px;
    }
  }
}
