.category-section {
  z-index: 20;
  position: relative;
  overflow: hidden;
}

//Testimonial style tweak specifics for ul styling etc
.cms-hunter-burn-testimonials {
  .testimonialssubmit a, .testimonialssubmit ul li::marker {
    color: #faa619;
  }
}
.testimonialssubmit {
  .container {
    padding-top: 0;
  }
  ul {
    padding-left: 2rem;
    li {
      list-style-type: disc;
      &::marker {
        color: #f15a29;
      }
    }
  }
}
body.category-stacks {
  background: #f9f9f9;
  .category-page-products {
    background: #f9f9f9;
  }
  .main-hero {
    background: #000000;
    color: #ffffff;
    margin-top: 55px;
    @include tablet {
      margin-top:0;
    }
    .container {
      padding: 1rem;
      @include desktop {
        padding: 3rem;
      }
    }
  }
  .main-hero .is-flex {
    align-items: center
  }
}
