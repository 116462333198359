.btn, .action, .action.primary.checkout {
  display: inline-block;
  transition: all ease .3s;
  padding: 8px 15px;
  color: black;
  border-radius: 5px;
  font-family: $font1;
  font-weight: 700;
  text-transform: uppercase;
  font-size: .9rem;
  position: relative;
  box-sizing: border-box;

  &.showcart {
    padding: 0
  }

  &:hover {
    text-decoration: none;
    color: white;
  }

  &:focus, &:visited {
    color: white;
  }

  &--small {
    padding: 5px 8px;
    font-size: .7rem;

    @include tablet {
      font-size: .8rem;
    }
  }

  &--blue, &.primary, &.checkout {
    background-color: $btn-blue;
    border-color: $blue;
    color: white;

    &:hover {
      background-color: $btn-blue-hover;
      border-color: $btn-blue-hover;
    }
  }

  &--red {
    background-color: $red;
    color: white;

    &:hover {
      background-color: lighten($red, 10%);
    }
  }

  &--black {
    background-color: $light-black;
    color: white;
    /*border: 2px solid $light-black;*/
    margin-right: 10px;

    &:hover {
      color: white;
      background-color: lighten($light-black, 10%);
    }
  }

  &--white {
    background: $light-grey;
    color: black !important;

    &:hover {
      background-color: $light-black;
      color: white !important
    }
  }

  &--bordered {
    border: 2px solid;
    background: none;

    &-white {
      border-color: white;
      color: white;

      &:hover {
        background-color: white;
        border-color: white;
        color: black;
      }
    }

    &-black {
      color: black;
      border-color: black;

      &:hover {
        background-color: $light-black;
        border: 2px solid black;
      }
    }

    &-red {
      color: $red;
      border-color: $red;
      background: white;

      &:hover {
        background-color: $light-black
      }
    }
  }

  &--link {
    font-weight: 500;
    padding: 0;
  }

  &--add-to-cart {
    position: relative;
    height: 40px;
    border-radius: 0 5px 5px 0;
    margin-left: 40px;

    &:hover {
      border: none;
      color: white;
    }

    &:visited {
      background-color: $btn-blue
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
    }

    &:before {
      top: 0;
      left: -40px;
      height: 40px;
      width: 40px;
      border-radius: 5px 0 0 5px;
      background: $blue-alt
    }

    &:after {
      height: 18px;
      width: 18px;
      left: -30px;
      top: 12px;
      background: url(../images/cms/cart-icon.svg) no-repeat;
      background-size: contain;
    }
  }

  &--cart {
    &:before {
      position: relative;
      display: inline-block;
      font-family: 'fontawesome';
      margin-right: 5px;
    }

    &.clear-cart {
      &:before {
        content: '\f00d';
      }
    }

    &.update-cart {
      &:before {
        content: '\f021';
      }
    }
  }

  &--test {
    background-color: $light-black;
    color: white;
    &:hover {
      background-color: $light-black;
      color:   #f15a29;
    }
  }
  &--burn {
    background-color: $light-black;
    color: white;
    &:hover {
      background-color: $light-black;
      color:  #faa619;
    }
  }
  &--focus {
    background-color: $light-black;
    color: white;
    &:hover {
      background-color: $light-black;
      color: #009FD0;
    }
  }
}


#to-top {
  display: none;
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 99;
  font-size: 1rem;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: $light-black;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;

  &:hover {
    background-color: $light-grey;

  }

  @include tablet {
    right: 26px;
  }
}
