.testimonials-section {
  overflow-x: hidden;

  .container {
    &:first-child {
      padding-bottom: 0;
    }
  }

  .container--wide {
    overflow-x: hidden;
  }
}

.testimonials {
  position: relative;

  .testimonial {
    opacity: .2;
    // color: white;

    body.page-products & {
      background-color: #fff;
      min-height: 440px;
    }
  }

  .center .testimonial {
    opacity: 1;
    &.test--orange {
      border: 4px solid #f15a29;
    }
  }

  /*@include desktop {
    &:before, &:after {
      content: '';
      width: 80px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 10;
    }

    body.page-products & {
      &:before {
        left: -10px;
        background: linear-gradient(to right, #f9f9f9, transparent);
      }

      &:after {
        right: -10px;
        background: linear-gradient(to left, #f9f9f9, transparent);
      }
    }
  }*/

  .testimonials__quote {
    font-weight: 700;
    line-height: 2.1rem;
  }
  &.testimonials--huntertest {

      h2 {
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 20px;
          height: 16px;
          background-image: url(../images/test/blockquote-symbol-orange.svg);
          background-size: contain;

          @include tablet {
            width: 40px;
            height: 32px;
          }
      }
      &:before {
        left: -20px;
        top: 0;

        @include tablet {
          left: -29px;
          top: -20px;
        }

        @include desktop {
          left: -55px;
          top: 0px
        }

      }
      &:after {
        right: -20px;
        bottom: 0;
        transform: rotate(180deg);

        @include tablet {
          right: -20px;
          bottom: -10px;
        }

        @include desktop {
          right: -45px;
          bottom: -10px;
        }

        @include desktop {
          bottom: 0;
        }
      }
    }
    img {
      width: 20%;
      margin: 0 auto;
      margin-bottom: 1rem;
      border-radius: 50%;
    }
    &.owl-theme .owl-dots {
      left:0;
      position: relative;
      top:-5px;
      .owl-dot.active span, .owl-dot:hover span {
        background:#f15a29;
      }
      @include desktop {
        align-items: center;
        display: flex;
        justify-content: center;
        top:0;
      }
    }
    .owl-item {
      opacity: 0;
      &.active {
        opacity:1;
      }
    }
  }
  h2 {
    line-height: initial;
  }
}

.testimonial {
  text-align: center;
  margin: 0 20px ;
  opacity: .5;
  transition: opacity ease .5s;
  width: 90vw;
  border-radius: 10px;

  &.test--orange {
    border: 4px solid #f15a29;
  }
  &.burn--yellow {
    border: 4px solid #faa619;
  }
  &.border--green {
    border: 4px solid #1d7137;
  }
  p {
    font-family: $font1;
  }

  @include desktop {
    width: 600px;

    body.page-products & {
      width: 480px
    }
  }

  .testimonial__quote,
  .testimonial__name {
    color: #162225;
    font-weight: 400;
  }

}

.testimonial__person {
  padding: 30px 0;
  font-family: $font1;
}


.testimonial__photo {
  position: relative;
  width: 124px !important;
  height: 124px;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
  padding: 6px;
  border: 3px solid currentColor;
  margin-bottom: 10px
}

.testimonial__photo.square {
  border:0;
  border-radius: 0;
  position: relative;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  padding:0;
  width: 100%!important;
}


.testimonial__name {
  @include m-text;
  margin-bottom: 5px !important;
  padding: 0 40px;
  color: white;
}

.testimonial__creds {
  @include s-text;
  color: $mid-light-grey;
  padding: 0 40px;
}

.testimonial__quote {
  position: relative;
  margin-bottom: 30px;
  padding: 0 40px;
  &__smallpadding {
    padding: 0 20px;
  }

  p {
    position: relative;
    font-family: $font1;
    //margin: 0;
  }

  body.page-products & {

    p {

      &:before,
      &:after{
        font-size: 5rem;
        position: absolute;
      }

      &:before {
        left: -50px;
        top: 10px;
      }

      &:after {
        right: -50px;
        bottom: -15px;
      }
    }
  }


}

.testimonial__link {
  background-color: #101820;
  border-radius: 0 0 10px 10px;
  border-top: 3px solid black;
  font-size: .9rem;
  transition: background-color .3s ease;
  overflow: auto;

  &:hover {
    background-color: currentColor
  }

  a {
    display: block;
    padding: 15px;
  }
}


// Carousel controls
.carousel__prev,
.carousel__next, .carousel__prev--testimonials,
.carousel__next--testimonials {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 30;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  transition: all ease .3s;
  padding: 5px;
  cursor: pointer;
  display: block;

  @include desktop {
    display: block;
    width: 60px;
    height: 60px;
  }

  &:hover {
    background-color: rgba(255,255,255, 1);
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;

    path {
      fill: #000;
    }
  }
}

.carousel__prev, .carousel__prev--testimonials {
  left: -305px;
  @include tablet {
    left: -750px;
  }
}

.carousel__next, .carousel__next--testimonials {
  right: -305px;
  transform: rotate(180deg);
  @include tablet {
    right: -750px;
  }
}

.testimonial-single {
  padding: 0;
  margin-bottom: 3rem;

  .testimonial__photo {
    margin: 0 auto;

    @include tablet {
      margin: 0;
    }
  }
}

.testimonial-single__header {
  height: 350px;
  width: 100%;
  position: relative;
  border-radius: 5px 5px 0 0;
  overflow: hidden;

  @include tablet {
    height: 400px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: '';
    height: 80px;
    width: 100%;
    background: linear-gradient(to top, #ffffff 0, rgba(22,34,37,0) 100%);
    display: block;
    position: absolute;
    bottom: 0;
  }
}

.testimonial-single__main {
   padding: 0 2rem 2rem;
   margin-top: -65px;
}

.testimonial-single__person {
  text-align: center;
  padding: 0;

  @include tablet {
    display: flex;
    align-items: center;
    position: relative;
    height: 130px;
    text-align: left;
  }
}

.testimonial-single__person-details {
  @include tablet {
    padding-left: 20px;
  }

  p {
    padding: 0;
  }
}

.testimonial-single__goal {
  text-align: center;
  position: relative;
  margin: 1rem 0 0;

  span {
    display: inline-block;
    position: relative;
    color: currentColor;
    margin: auto;
    background: #ffffff;
    padding: 0 10px;
  }

}

.testimonial-single__goal-inner {
  border: 2px solid currentColor;
  padding: 6px 15px;
  border-radius: 10px;
  margin-top: -12px;
}

.testimonial-single__quote {
  margin-top: 2rem;
  position: relative;

  p {
    color: #162225;
  }

  &:before,
  &:after{
    content: '\201C';
    font-family: $font1;
    font-size: 5rem;
    z-index: 20;
    position: absolute;
    color: currentColor;
    font-weight: 700;
  }

  &:before {
    left: -50px;
    top: -26px;
  }

  &:after {
    content: '\201D';
    right: -50px;
    bottom: -56px;
  }

  img {
    display: block;
    margin-bottom: 2rem;
  }

}


.catalog-product-view {
  .testimonials {
    .testimonial {
      padding: 1rem;
      .testimonial__quote,.testimonial__name {
        color: #fff;
      }
    }
  }
}
