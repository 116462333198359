.customer-account-login, .customer-account-create, .customer-account-forgotpassword, .customer-account-index {
  #create-account, #send2, #create-account.action.submit.primary, #forgot-password.action.submit.primary {
    // font-size: 1rem;
    // padding: 5px 20px;
    // background-color: #f1f1f1;
    // color: $light-black;
  }

  .text {
    color: $light-black !important;
  }

  .text:not(:last-child) {
    margin-bottom: 0;
  }

  .field-error, div.mage-error[generated] {
    margin-top: 0px;
    font-size: 1rem;
  }

  .message.error > *:first-child:before {
    margin: -16px 0 0;
  }

  .page.messages {
    margin-top: 55px;
    display: block;

    @include tablet {
      margin-top: 0;
      padding-left: 30px;
    }

  }

  a.action.remind, a.action.back {
    color: $light-black;
    cursor: pointer;
    text-decoration: none;
  }

  a.action.remind:hover, a.action.back:hover {
    color: $hunter-orange;
    cursor: pointer;
    text-decoration: none;
  }

  .login-container .fieldset:after {
    margin: 20px 0 0 0;
    color: $hunter-orange;
    font-size: 1rem;
  }
}

.account-nav {
  .content {
    background: $lightest-grey;
    padding: 0;

    .nav.items{
      list-style: none;
      margin-left: 0;
      padding: 20px;

      .item {

        &.current {
          strong {
            color: $hunter-orange;
            border-color: $hunter-orange;
            background: white;
            padding: 10px;
          }
        }

        a {
          color: $light-black;
          cursor: pointer;
          text-decoration: none;
          padding: 10px;

          &:hover, &:focus {
            color: $hunter-orange;
            cursor: pointer;
            text-decoration: none;
            background-color: white;
          }
        }
      }
    }
  }

}


.block-dashboard-info, .block-dashboard-addresses, .sales-order-history, .sales-order-view {
  a.action.edit, a.action.change-password, a.action.back {
    color: $light-black;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }
  a.action.edit:hover, a.action.edit:focus,
  a.action.change-password:hover, a.action.change-password:focus,
  a.action.back:hover, a.action.back:focus {
    color: $hunter-orange;
    cursor: pointer;
    text-decoration: none;
  }
}
.block-dashboard-orders, #my-orders-table {
  a.action.view {
    color: $hunter-orange;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
  }
  a.action.view:hover, a.action.view:focus {
    color: $light-black;
    cursor: pointer;
    text-decoration: none;
  }
}

.login-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .btn--link {
    margin: 20px 0 0 0;
    color: $hunter-orange;
    text-transform: initial;

    @include tablet {
      margin: 0 0 0 30px
    }
  }

  @include tablet {
    flex-direction: row;
  }
}
.customer-account-login #send2, .btn--blue, #forgot-password {
  font-size: .9rem;
  color: white !important;
  background-color: $btn-blue
}

.forgot-password-form, .form-edit-account {
  label:not(.floated-label) {
    display: block !important;
    float: none !important;
    padding: 0 !important;
    text-align: left !important
  }
}
