.country-selection-list--list {
  .country-selection-list--item {
    width:100%;
    margin: 0 auto;
    margin-bottom: 20px;

    a {
      font-family: 'Open Sans', sans-serif;
      padding: 10px 20px;
      border: 1px solid #ccc;
      text-decoration: none;
      display:block;
      color: #fff;

      &:hover,
      &:focus {
        color: #bbb;
      }
    }
  }
}
