.hero--focus {
  background-image: url(../images/cms/hunter-focus-wave-bg.jpg);
  background-image: url(../images/cms/hunter-focus-wave-bg.webp);
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 10;
    height: 150px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
    bottom: 0;
    left: 0;
  }

  .hero-content {
    @include desktop {
      align-items: flex-start;
      width: 68.33333%;
    }
  }

  .hero__title {
    @include desktop {
      font-size: 2.7rem;
      margin-bottom: 0.25rem;
      text-align: left;
    }
  }
  .hero__subtitle {
    @include desktop {
      text-align: left;
    }
  }

  .hero__product {
    width: 350px;
    margin: 0 auto;

    @include desktop {
      max-height: 520px;
      max-width: 500px;
      width: auto;
      position: absolute;
      right: 69%;
      top: 5px;
      bottom: 0;
      margin: auto;
    }
  }

  .container{
    height: 100%;

    @include desktop {
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 2.5rem;
    }
  }
  @include desktop {
    min-height: 72vh;
  }
}

.focus-enjoy-now {
  padding-bottom: 0;
}
