.burn-section--1 {
  img {
    border-radius: 4px;
  }
  .main-img {
    img {
      border-radius: 10px;
    }
  }
  h2 {
    margin-bottom: 30px;

    @include tablet {
      margin-bottom: 50px;
    }
  }
  .is-flex {
    align-items: center;
  }
  .columns {
    .column {
      &:first-child {
        order:1;
        @include tablet{
          order: initial;
        }
      }
      &:nth-child(2){
        order:2;
        @include tablet{
          order: initial;
        }
      }
      &:nth-child(3) {
        order:3;
        @include tablet{
          order: initial;
        }
      }
      &:nth-child(4){
        order:5;
        @include tablet{
          order: initial;
        }
      }
      &:nth-child(5){
        order:4;
        @include tablet{
          order: initial;
        }
      }
    }
  }
}

.burn-section--2 {
  //background-image: url(../images/cms/hunter-burn-2.jpg);
}

.burn-section--4 {
  .owl-carousel .owl-item img {
    margin: 0px auto 0;
    width: 25%;
  }
}

.burn-section--5 {
  background-image: url(../images/cms/hunter-burn-3-mob.jpg);

  @include tablet {
    background-image: url(../images/cms/hunter-burn-3.jpg);
  }
  .main-img {
    img {
      border-radius: 10px;
    }
  }
}

.burn-section--6 {
  @include desktop {
    padding-bottom: 2rem;
  }
}

.burn-section--7 {
  background: black url(../images/cms/burn-background444.png) center top no-repeat;
}

.burn-section--9 {

}
.burn-section--10 {
  .faq-block  {
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include tablet {
      margin-top: 3rem;
    }
  }
}

.burn-section--11 {
  background-image: url(../images/cms/hunter-burn-9-mob.jpg);
  padding: 100px 0;
  background-position: top;

  @include tablet {
    background-image: url(../images/cms/hunter-burn-9.jpg);
    padding: 0 0 270px;
  }
  .main-img {
    img {
      border-radius: 10px;
    }
  }
}

.burn-section--12 {
  background-image: url(../images/cms/hunter-burn-10-mob.jpg);
  background-position: top;

  @include tablet {
    background-image: url(../images/cms/hunter-burn-10.jpg);
  }
}

.burn-gradient {
  //background-image: linear-gradient(to bottom, #060401, #442e07);
  background: #f9f9f9;
}

.burn-gradient-1 {
  background: linear-gradient(to bottom, #000, #442e07);
}

.burn-gradient-2 {
  background: linear-gradient(to bottom, #000, $hunter-yellow);
}

.burn-gradient-3 {
  background: linear-gradient(to bottom, $light-black, $hunter-yellow);
}

.burn-table {
  background: #fff;
  min-width: 370px;

  h2 {
    line-height: 1rem;
    margin: 0;
    padding: 0.5rem;
    .is-size-6 {
      font-weight: 600;
    }
  }
  .is-borderless {
    border: none!important;
  }
  .border-bottom {
    border-bottom: 1px solid #000!important;
  }
  .footnotes {
    padding: 0.5rem;
    p {
      line-height: 1rem;
    }
  }
}

.yellow-wave {
  background-image: url(../images/burn/wave-background-yellow@2x.png);
  background-image: url(../images/burn/wave-background-yellow@2x.webp);
  background-size: 2200px 1035px;
  background-position: 0% 62%;
  overflow: hidden;
  @include tablet {
    background-position: 0% 62%;
  }
}

.multi-wave {
  background-image: url(../images/cms/wave-background-multi-light@2x.png);
  background-image: url(../images/cms/wave-background-multi-light@2x.webp);
  background-size: 2200px 1035px;
  background-position: 0% 35%;
  overflow: hidden;
  @include tablet {
    background-position: 0% 20%;
  }
  &.ingredients-page {
    background-position: 61% -32%;
    @include tablet {
      background-position: 61% 52%;
    }
    .white-gradient {
      background: rgba(255, 255, 255, 0.7);
    }
  }
}
