.hero--test {
  background-image: url(../images/cms/hunter-test-wave-bg.jpg);
  background-image: url(../images/cms/hunter-test-wave-bg.webp);
  @include tablet {
    min-height:inherit;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 10;
    height: 150px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
    bottom: 0;
    left: 0;
    @include widescreen {
      display: none;
    }
  }

  .hero-content {
    @include desktop {
      align-items: flex-start;
      width: 78.33333%;
    }
  }

  .hero__title {
    @include desktop {
      font-size: 2.35rem;
      //line-height: 2.6rem;
      margin-bottom: 0.25rem;
      text-align:left;
    }
  }
  .hero__subtitle {
    @include desktop {
      text-align: left;
    }
  }
  .hero__product {
    width: 350px;
    margin: 0 auto;

    @include desktop {
      max-height: 520px;
      max-width: 500px;
      width: auto;
      position: absolute;
      right: 69%;
      top: 5px;
      bottom: 0;
      margin: auto;
    }
  }

  .mobile-gradient {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 46%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 46%, rgba(0,0,0,0) 100%);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 46%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    z-index:11;
    @include tablet {
      height: 39%;
    }
    @include desktop {
      display: none;
    }
  }

  .container{
    height: 100%;

    @include desktop {
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 2.5rem;
    }
  }
  @include desktop {
    min-height: 40vh;
  }
  @include widescreen {
    min-height: 72vh;
  }
  &.hero--test-1 {
    background: #000 url(../images/test/people-jumping-mobile.jpg);
    background-repeat: no-repeat;
    background-position: 70% -39%;
    background-size: 250%;
    @include tablet {
      background-image: url(../images/test/people-jumping.jpg);
      background-position: 60% 39%;
      min-height: 53vh;
    }
    @include desktop {
      background-position: 57% 39%;
      min-height: 72vh;
    }
    @include widescreen {
      background-position: 84% 39%;
      backgrond-size: 220%;
    }
    .hero__product {
      position: relative;
      left: 3%;
      transform: rotate(24deg);
      @include tablet {
        transform: rotate(24deg);
        position: absolute;
        width: 500px;
        lefT: -10%;
        top: -12%;
      }
      @include desktop {
        left: initial;
        right: 58%;
      }
    }
    .hero-content {
      padding-top: 6rem;
      &.is-flex-direction-column {
        @include tablet {
            flex-flow: row!important;
        }
      }
      @include tablet {
        padding-left: 19rem;
        padding-top: initial;
        width: 100%;
      }
      @include desktop {
        padding-left:0;
        width: 68%;
      }
    }
    .centered-mobile {
      align-items: center;
      display: flex;
      flex-flow: column;
      @include tablet {
        align-items: flex-start;
      }
    }
  }

  &.hero--test-2 {
    background: #000 url(../images/test/guy-with-barbell-mobile.jpg);
    background-repeat: no-repeat;
    background-position: 70% -4%;
    background-size: 150%;
    @include tablet {
      background-image: url(../images/test/guy-with-barbell.jpg);
      background-position: 60% 39%;
      background-size: 190%;
      min-height: 53vh;
    }
    @include desktop {
      background-position: 57% 39%;
      min-height: 72vh;
    }
    @include widescreen {
      background-size: 100%;
      background-position: 100% 27%;
    }
    .hero__product {
      position: relative;
      left: 3%;
      transform: rotate(24deg);
      @include tablet {
        transform: rotate(24deg);
        position: absolute;
        width: 500px;
        lefT: -10%;
        top: -12%;
      }
      @include desktop {
        left: initial;
        right: 58%;
      }
    }
    .hero-content {
      padding-top: 6rem;
      &.is-flex-direction-column {
        @include tablet {
            flex-flow: row!important;
        }
      }
      @include tablet {
        padding-left: 19rem;
        padding-top: initial;
        width: 100%;
      }
      @include desktop {
        padding-left:0;
        width: 68%;
      }
    }
    .centered-mobile {
      align-items: center;
      display: flex;
      flex-flow: column;
      @include tablet {
        align-items: flex-start;
      }
    }
  }

  &.hero--test-3 {
    background: #000 url(../images/test/guy-smiling-mobile.jpg);
    background-repeat: no-repeat;
    background-position: 70% -39%;
    background-size: 250%;
    @include tablet {
      background-image: url(../images/test/guy-smiling.jpg);
      background-position: 60% 39%;
      min-height: 53vh;
    }
    @include desktop {
      background-position: 57% 39%;
      min-height: 72vh;
    }
    @include widescreen {
      background-position: 49% 50%;
      background-size: 110%;
    }
    .hero__product {
      position: relative;
      left: 3%;
      transform: rotate(24deg);
      @include tablet {
        transform: rotate(24deg);
        position: absolute;
        width: 500px;
        lefT: -10%;
        top: -12%;
      }
      @include desktop {
        left: initial;
        right: 58%;
      }
    }
    .hero-content {
      padding-top: 6rem;
      &.is-flex-direction-column {
        @include tablet {
            flex-flow: row!important;
        }
      }
      @include tablet {
        padding-left: 19rem;
        padding-top: initial;
        width: 100%;
      }
      @include desktop {
        padding-left:0;
        width: 68%;
      }
    }
    .centered-mobile {
      align-items: center;
      display: flex;
      flex-flow: column;
      @include tablet {
        align-items: flex-start;
      }
    }
    .mobile-gradient {
      height: 70%
    }
  }

  &.hero--test-4 {
    background: #000 url(../images/test/guy-pressup-v2-mobile.jpg);
    background-repeat: no-repeat;
    background-position: 42% -48%;
    background-size: 250%;
    @include tablet {
      background-image: url(../images/test/guy-pressup-v2.jpg);
      background-position: 40% 39%;
      min-height: 53vh;
    }
    @include desktop {
      background-position: 43% 39%;
      background-size: 100%;
      min-height: 72vh;
    }
    .hero__product {
      position: relative;
      left: 3%;
      transform: rotate(24deg);
      @include tablet {
        transform: rotate(24deg);
        position: absolute;
        width: 500px;
        lefT: -10%;
        top: -12%;
      }
      @include desktop {
        left: initial;
        right: 58%;
      }
    }
    .hero-content {
      padding-top: 6rem;
      &.is-flex-direction-column {
        @include tablet {
            flex-flow: row!important;
        }
      }
      @include tablet {
        padding-left: 19rem;
        padding-top: initial;
        width: 100%;
      }
      @include desktop {
        padding-left:0;
        width: 68%;
      }
    }
    .centered-mobile {
      align-items: center;
      display: flex;
      flex-flow: column;
      @include tablet {
        align-items: flex-start;
      }
    }
  }

  &.hero--test-5 {
    background: #000 url(../images/test/guy-pressup-v3-mobile.jpg);
    background-repeat: no-repeat;
    background-position: 40% -11%;
    background-size: 140%;
    @include tablet {
      background-image: url(../images/test/guy-pressup-v3.jpg);
      background-position: 26% 71%;
      min-height: 53vh;
    }
    @include desktop {
      background-position: 20% 69%;
      min-height: 72vh;
    }
    @include widescreen {
      background-size: 140%;
      background-position: 0% 69%;
    }
    .hero__product {
      position: relative;
      left: 3%;
      transform: rotate(24deg);
      @include tablet {
        transform: rotate(24deg);
        position: absolute;
        width: 500px;
        lefT: -10%;
        top: -12%;
      }
      @include desktop {
        left: initial;
        right: 58%;
      }
    }
    .hero-content {
      padding-top: 6rem;
      &.is-flex-direction-column {
        @include tablet {
            flex-flow: row!important;
        }
      }
      @include tablet {
        padding-left: 19rem;
        padding-top: initial;
        width: 100%;
      }
      @include desktop {
        padding-left:0;
        width: 68%;
      }
    }
    .centered-mobile {
      align-items: center;
      display: flex;
      flex-flow: column;
      @include tablet {
        align-items: flex-start;
      }
    }
  }
  &.hero--test-6 {
    background: #000 url(../images/test/man-squatting.jpg);
    background-repeat: no-repeat;
    background-position: 40% -11%;
    background-size: 140%;
    @include tablet {
      background-position: 26% 46%;
      min-height: 53vh;
    }
    @include desktop {
      background-position: 20% 69%;
      min-height: 72vh;
    }
    @include widescreen {
      background-size: 107%;
    background-position: 0% 29%;
    }
    .hero__product {
      position: relative;
      left: 3%;
      transform: rotate(24deg);
      @include tablet {
        transform: rotate(24deg);
        position: absolute;
        width: 500px;
        lefT: -10%;
        top: -12%;
      }
      @include desktop {
        left: initial;
        right: 58%;
      }
    }
    .hero-content {
      padding-top: 6rem;
      &.is-flex-direction-column {
        @include tablet {
            flex-flow: row!important;
        }
      }
      @include tablet {
        padding-left: 19rem;
        padding-top: initial;
        width: 100%;
      }
      @include desktop {
        padding-left:0;
        width: 68%;
      }
    }
    .centered-mobile {
      align-items: center;
      display: flex;
      flex-flow: column;
      @include tablet {
        align-items: flex-start;
      }
    }
  }
}
