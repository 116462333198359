
.focus-nootropics {
  z-index: 20;
  color: white;
  padding-top: 0;
  position: relative;

  &:before {
    background-position: bottom;
  }


}

.focus-nootropics__top-content {
  border-radius: 10px;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include desktop {
    //padding: 30px;
    margin-top: 0;

    img {
      margin-right: 20px
    }
  }
  .category-card-title-offset {
    margin-top:0;
    text-align: left;
    h2 {
      text-align: left;
    }
  }
}

.focus-nootropics__features {
  margin-top: 20px;
}

.focus-nootropic__feature {
  display: flex;
  align-items: flex-start;
}

.focus-nootropic__feature-content {
  padding-top: 3px
}

.focus-nootropic__feature-title {
  font-family: $font1;
  font-weight: 700;
  font-size: 1.15rem;
  letter-spacing: 2px;
  margin-bottom: 16px;
}

.focus-nootropics__cta-full-content {
  text-align: center;
  width: 100%;

  @include desktop {
    padding-left: 80px;
    text-align: left;
  }
}
