.references {
  color: #fff;
  &.focus-blue {
    h5 {
      color: #009FD0!important;
      i {
        margin-left: 10px;
        transition: all 0.5s;
        &:before, &:after {
          opacity: 1!important;
        }
      }
      &.active {
        i {
          transform: rotate(45deg);
        }
      }
    }
    p, ol {
      color: #6e6e6e
    }
  }
  &.focus-green {
    h5 {
      color: #6dab72!important;
      i {
        margin-left: 10px;
        transition: all 0.5s;
        &:before, &:after {
          opacity: 1!important;
        }
      }
      &.active {
        i {
          transform: rotate(45deg);
        }
      }
    }
    p, ol {
      color: #6e6e6e
    }
  }

  &.test-orange {
    h5 {
      color: #f15a29!important;
      i {
        margin-left: 10px;
        transition: all 0.5s;
        &:before, &:after {
          opacity: 1!important;
        }
      }
      &.active {
        i {
          transform: rotate(45deg);
        }
      }
    }
    p, ol {
      color: #6e6e6e
    }
  }
  &.test-blue {
    h5 {
      color: #377f9b!important;
      i {
        margin-left: 10px;
        transition: all 0.5s;
        &:before, &:after {
          opacity: 1!important;
        }
      }
      &.active {
        i {
          transform: rotate(45deg);
        }
      }
    }
    p, ol {
      color: #6e6e6e
    }
  }

  &.burn-yellow {
    h5 {
      color: #faa619!important;
      i {
        margin-left: 10px;
        transition: all 0.5s;
        &:before, &:after {
          opacity: 1!important;
        }
      }
      &.active {
        i {
          transform: rotate(45deg);
        }
      }
    }
    p, ol {
      color: #6e6e6e
    }
  }

  &.burn-green {
    h5 {
      color: #82a31e!important;
      i {
        margin-left: 10px;
        transition: all 0.5s;
        &:before, &:after {
          opacity: 1!important;
        }
      }
      &.active {
        i {
          transform: rotate(45deg);
        }
      }
    }
    p, ol {
      color: #6e6e6e
    }
  }
  &.burn-peach {
    h5 {
      color: #f15a29!important;
      i {
        margin-left: 10px;
        transition: all 0.5s;
        &:before, &:after {
          opacity: 1!important;
        }
      }
      &.active {
        i {
          transform: rotate(45deg);
        }
      }
    }
    p, ol {
      color: #6e6e6e
    }
  }

  ol {
    margin: 0 0 0 14px;
    padding: 0;
    list-style-position: outside;
  }

  li {
    font-size: 0.8rem;
    word-break: break-word;
    margin-bottom: .25rem;
  }
}
