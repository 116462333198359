.footer-links,
.footer-copyright {
  background-color: #000;
  color: white;

  a {
    color: white
  }
}

.footer-links {
  .container {
    // padding-top: 0;
  }

  a {
    font-size: 1rem;
  }
}

.footer-links__payments-list {
  li {
    display: inline-block;
  }

  img {
    width: 75px;
  }
}

.footer-copyright {
  @include s-text;
  margin-bottom: 0;

  .container {
    padding: 1rem 0;
  }

  .columns {
    margin-bottom: 0
  }

  border-top: 1px solid $light-black
}
