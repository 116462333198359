.category-page-products {
  .product {
    font-family: $font1;
    margin-bottom: 1rem;
    p {
      margin-bottom: 0;
    }
  }
}

.category-page-products-deals {
  .product {
    font-family: $font1;
    p {
      margin-bottom: 0;
    }
  }
}

.product__image-wrapper {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include desktop {
    margin-bottom: 0;
  }
}

.product__image-wrapper-deals {
  display: flex;
  align-items: flex-end;
  padding-top: 0px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include desktop {
    margin-bottom: 0;
  }
}

.product__image-inner-wrapper {
  width: 100%;
  position: relative;
  img {
    display: block;
  }
}

.product__image {
  width: 100%;
}

.product__image-deals {
}

.product-info__top {
  top: 10px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    padding: 1rem 0;
    padding-bottom: .3rem;
    @include font-size(1.65,1.85);
    margin: 0;

    @include desktop {
      @include font-size(1.5,1.75);
      margin: 0;
      padding: 0 0 1rem;
    }
  }

  span {
    display: block;
    font-size: 1rem;
  }
}

.product-info__middle {
  position: relative;

  @include desktop {
    text-align: left;
  }

  p {
    margin-bottom: 0;
  }
}

.product-info__bottom {

  span {
    margin-left: 5px;
  }
}

.product-1 {
  .product__image-wrapper {

    body[class*="hunter-test"] &{
      &:before {
        background-image: linear-gradient(to bottom, #5d1c0c, $hunter-orange);
      }
    }

    body[class*="hunter-burn"] & {
      &:before {
        background-image: linear-gradient(to bottom, #5a3d0b, $hunter-yellow);
      }
    }

    body[class*="hunter-focus"] & {
      &:before {
        background-image: linear-gradient(to bottom, #064354, $hunter-blue);
      }
    }
  }

  .product__image {
    // margin-bottom: 0;
  }
}

.product-info__delivery {
  display: inline-block;
  border: 2px solid;
  @include font-size(0.8, 1);
  padding: 6px 10px;
  margin-top: 20px;
  border-radius: 10px;
  display: inline-flex;

  span {
    margin: 0 3px 0 5px;
  }

  &:before {
    content: '';
    width: 19px;
    height: 16px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: contain;
  }

  body[class*="hunter-test"] & {
    border-color: $hunter-orange;

    &:before {
      background-image: url(../images/cms/icon-truck-test.svg);
    }
  }

  body[class*="hunter-burn"] & {
    border-color: $hunter-yellow;

    &:before {
      background-image: url(../images/cms/icon-truck-burn.svg);
    }
  }

  body[class*="hunter-focus"] & {
    border-color: $hunter-blue;

    &:before {
      background-image: url(../images/cms/icon-truck-focus.svg);
    }
  }
}

.product__price {
  padding-bottom: 10px;

  .price {
    @include l-text;
    font-weight: 700
  }
}

.product__price-deals {
  padding-bottom: 5px;
  .rrp {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
    text-decoration-color: #f9b118;
  }

  .savings {
    color: #d32b05;
    font-weight: bold;
  }
}

.product-info__order-info {
  bottom: 20px;
  left: 0;
  right: 0;
}

.product-info__order-info-deals {
  position: relative;
}

.product-benefit-image {
  max-width: 100px;
}

body[class*="hunter-test"]  {
  .category-page-products {
    //background-image: linear-gradient(to bottom, #431b0f, $hunter-orange);
    background: #f9f9f9;
  }
}

body[class*="hunter-burn"]  {
  .category-page-products {
    //background-image: linear-gradient(to bottom, #442e07, $hunter-yellow);
    background: #f9f9f9;
  }
}

body[class*="hunter-focus"]  {
  .category-page-products {
    //background-image: linear-gradient(to bottom, #066a83, $hunter-blue);
    background: #f9f9f9;
  }
}

.deals-background {
  background-image: url(../images/deals/hero-hunter-deals.jpg);
  background-color: #000;
  background-position: 10% 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  min-height: 350px;

  @include tablet {
    background-position: 35% 50%;
  }

  @include desktop {
    background-position: 50%;
  }

  p {
    color: #FFF;
  }
}

.zpbm {
  padding-bottom: 0;
  @include tablet {
    padding: 0.75rem;
  }
}
.product-info__bottom {
  .block-mobile {
    display: inline-block;
  }
}
.category_usps {
  align-items: center;
  display: flex;
  margin-top: 2rem;
  position: relative;

  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    @include tablet {
      align-items: stretch;
      margin-top: 1rem;
      //flex-flow: column;
      width: 100%;
    }
    li {
      align-items: center;
      background: #f9f9f9;
      //border: 1px solid #176861;
      color: #404ea5;
      border-radius: 10px;
      display: flex;
      flex-flow: row;
      font-weight: bold;
      font-size: 0.7rem;
      margin: 0.5%;
      padding: 0.5rem;
      position: relative;
      text-align: left;
      width: 49%;

      .fad {
        color: #404ea5;
        font-size: 1.5rem;
        margin-right: 0.5rem;
        @include tablet {
          font-size: 1rem;
        }
        @include desktop {
          font-size: 1.2rem;
        }
      }
      @include tablet {
        font-size: 0.75rem;
        text-align: left;
        //width: 100%;
        flex-flow: row;
        display: flex;
        width: 31.333%;
      }
      &:first-child {
        @include tablet {
          margin-left:0;
        }
      }

      &:last-child {
        justify-content: center;
        width: 100%;
        @include tablet {
          //justify-content: flex-start;
          //margin-bottom: 0;
          width: 33.333%;
        }
      }
    }
  }

  @include tablet {
    margin-top:0;
  }

  /*@include tablet {
    margin-top: 0;
    position: absolute;
    right: -10%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 25%;
  }*/

  &--dark {
    position: relative;
    right: 0;
    top: 0;
    transform: none;
    width: 100%;
    ul {
      flex-flow: row wrap;
      margin-top: 1rem;
      @include desktop {
        margin-bottom:0;
      }
      li {
        //background: #6d8387;
        border-radius: 4px;
        //color: #fffdfd;
        width: 100%;
        /*.fad {
          color: #ffffff;
        }*/
        @include desktop {
          width: 49%;
        }
        &:last-child {
          justify-content:flex-start;
          @include desktop {
            justify-content:center;
            width: 100%;
          }
        }
      }
      li {
        @include desktop {
          font-size: 0.9rem;
        }
      }
    }
  }
}

/**Boss Workout area**/

.card {
  .card__inner.is-flex {
    .column {
      &.card--content {
        order: 4;
        @include tablet {
          order: inherit;
        }
      }
    }
    .boss__workout {
      p {
        font-size: 0.8rem;
        @include tablet {
          font-size: 1rem;
        }
      }
    }
  }
}

.product-info__boss {
  display: flex;
  flex-flow: row wrap;
  p {
    text-transform: uppercase;
    width: 100%;
  }
  img {
    align-self: center;
    margin: 0.5%;
    width: 49%;
    @include tablet {
      width: 24%
    }
  }
}
