.page-header {
  margin-bottom: 0;
  max-height: 100px;
  position: relative;
  z-index: 30;
  border: 0;
  background-color: #000;
}

.primary-header {
  background-color: #000;
  padding: 0px;
  height: 55px;
  z-index: 100;
  width: 100%;
  position: fixed;

  &.navbar {
    display: block;
  }

  @include tablet {
    position: relative;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 .75rem;
    position: relative;
    height: 100%;

    @include tablet {
      max-width: $breakpoint-lg;
      margin: 0px auto;
      height: 100%;
    }
  }

  &__logo {
    z-index: 30;
    height: 55px;
    position: absolute;
    left: 0;
    right: 0;
    max-width: 120px;
    margin: auto;
    display: flex;

    img {
      text-align: center;
      margin: 0 auto;
    }

    @include tablet {
      left: .75rem;
      right: auto;
      margin: 0;
      max-width: 140px;
    }
  }

  &__nav-wrapper {
    &.is-open {
      transform: translateX(0);
    }

    @media (max-width: $breakpoint-sm) {
      position: fixed;
      height: calc(100% - 55px);
      top: 55px;
      width: 100%;
      transition: transform .3s cubic-bezier(0.860, 0.000, 0.070, 1.000);
      transform: translateX(-100%);
      background: #000;
      z-index: 40;
    }

    @include tablet {
    }
  }

  &__nav, &__top-links {
    @include tablet {
      display: flex;
      align-items: center;
    }
  }

  &__nav {
    padding: 1rem 0;

    @include tablet {
      padding: 0;
      justify-content: center;
    }
  }

  &__top-links {
    border-top: 1px solid $light-black;
  }

  &__cart-mobile {
    @include tablet {
      display: none;
      visibility: hidden;
    }
  }
}

.primary-header__top-links {
  padding: 1.5rem;

  @include tablet {
    padding: 0;
    position: absolute;
    top: 0;
    right: 60px
  }

  .primary-nav__link {
    padding: 1rem 1rem 1rem 0;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    position: relative;

    @include tablet {
      &:after {
        content: '';
        height: 50%;
        width: 1px;
        background: $mid-light-grey;
        display: inline-block;
        position: absolute;
        right: 0;
      }
    }
  }
}

.primary-header__top-links-item {
  font-weight: normal;

  > a {
    color: #ffffff;
    transition: color 0.5s;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-size: contain;
    font-weight: 700;
    padding: 10px 0;
    display: flex;
    align-items: center;
    margin-right: 5px;

    @include tablet {
      width: 44px;
      height: 44px;
      justify-content: center;
    }
  }

  &--switcher{
    span {
      font-weight: 700;
      margin-left: 2px;

      @include tablet {
        display: none;
      }
    }
  }

  &--my-account,
  &--cart {
    > a {
      position: relative;

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  &--my-account {
    a {
      span {
        margin-left: 14px;

        @include tablet {
          display: none;
        }
      }

      &:before {
        background-image: url(../images/cms/account-icon.svg);
      }
    }
  }

  &--cart {

    position: absolute;
    right: .75rem;
    top: 7px;

    > a {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;

      &:before {
        background-image: url(../images/cms/cart-icon.svg);
      }
    }

    .counter {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 5;
      display: block;
      font-size: 10px;
      background: $blue;
      color: #fff;
      padding: 1px 5px;
      border-radius: 20px;
      display: inline-block;
      font-weight: bold;
    }
  }
}

.category-title-link {
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    background-image: url(../images/hunter_bullet.svg);
    width: 22px;
    height: 23px;
    position: relative;
    background-size: cover;
    margin: 0 17px 0 2px;;
    transform: translateY(-50px);
    transition: all ease-in-out .2s;
    display: none;
    overflow: hidden;
    pointer-events: none;

    @include tablet {
      display: block;
      opacity: 0;
    }
  }

  &.in-view {
    &:before {
      transform: translateY(0);
      opacity: 1;
      pointer-events: auto;
    }
  }
}

/* Secondary Header */

body[class*="cms-page-view"], body[class*="catalog-category-view"] {
  &[class*="focus"], &[class*="test"], &[class*="burn"]  {
    .secondary-header {
      display: block;
      visibility: visible;
    }

    .page.messages.container {
      padding-top: 70px;
      display: block;

      @include tablet {
        padding: 0;
      }
    }
  }
}

.secondary-header {
  background: #fff;
  display: none;
  visibility: hidden;
  position: fixed;
  top: 55px;
  width: 100%;

  @include tablet {
    position: relative;
    top: 0;

    &.fixed-header {
      position:fixed;
      top: 0;
      left: 0;
    }
  }
}

.secondary-header__inner {
  display: flex;
  justify-content: center;
  padding: 0 1.5rem;
  max-width: 1152px;
  margin: auto;
  height: 50px;
  align-items: center;
  z-index: 30;
  position: relative;

  @include tablet {
    padding: 0 .75rem;
    justify-content: space-between;
  }
}

.secondary-nav-toggle {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.is-open {
    &:before {
      transform: rotate(180deg);
    }
  }

  &:before {
    content: '';
    background-image: url(../images/cms/down-chevron.svg);
    background-size: contain;
    width: 20px;
    height: 20px;
    display: block;
    transition: transform .2s ease;
  }

  @include tablet {
    display: none;
  }
}

.secondary-header__navigation {
  background-color: white;
  position: absolute;
  width: 100%;
  top: 40px;
  left: 0;
  display: none;
  padding: .5rem 1.5rem 2rem 1.5rem;

  &.is-open {
    display: block;

    ul.is-open {
      @for $i from 1 through 10 {
  			li:nth-child(#{$i}) {
  				transition-delay:(#{$i*0.10s});
  				opacity: 1;
  			}
  		}
    }
  }

  @include tablet {
    justify-content: center;
    display: flex;
    position: relative;
    top: 0;
    width: auto;
    display: block;
    padding: 0;
    min-width: auto;
  }

  ul {
    position: relative;

    @include tablet {
      display: flex;
      height: 100%;
    }
  }

  li {
    margin: 0;
    border-bottom: 2px solid $lightest-grey;
    opacity: 0;
    transition: opacity ease .3s;

    @include tablet {
      opacity: 1;
      border: none
    }

    &:last-child {
      border: none;
    }
  }

  a {
    color: #000;
    font-weight: 600;
    font-size: 0.9rem;
    display:block;
    padding: 12px 0;


    @include tablet {
      padding: 0 15px;
    }

    &:hover, &:focus {
      color: darken(#6e6e6e, 20%);
      text-decoration: none;
    }
  }
}

.secondary-header__brand {
  display: flex;
  justify-content: center;
  align-items: center;

  &-title {
    text-transform: uppercase;
    font-family: $font1;
    font-weight: 600;
    letter-spacing: 3px;
    display: block;
  }
}

.secondary-header__order {
  @media (max-width: $breakpoint-sm) {
    position: absolute;
    right: 1rem;
  }
}

/* Modile Top Nav */

@include max-screen($screen__s) {
  .navigation-top-links .my-cart {
    margin: 0px;
  }
}

/* Cart Overlay */

.minicart-block {
  // position: relative;

  .block-minicart {
    margin-bottom: 0px;
    border-radius: 0 0 8px 8px;
  }

  .mage-dropdown-dialog {
    background: white;
    z-index: 99;
    position: fixed;
    left: 0;
    right: 0;
    top: 120px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    padding: 0;

    @include tablet {
      position: absolute;
      width: 350px;
      right: 0;
      top: 49px;
      left: auto;
      border-radius: 0 0 8px 8px;
    }

    &:after {
      bottom: 100%;
      right: 30px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(38, 38, 38, 0);
      border-bottom-color: white;
      border-width: 8px;

      @include tablet {
        right: 13px
      }
    }

    #btn-minicart-close {
      background: none;
      position: absolute;
      top: 0;
      right: 0px;
      border: none;
      color: black;
      box-shadow: none;

      &:before {
        font: normal normal normal 18px/1 FontAwesome;
        content: "\f00d";
        margin-left: 10px;
        margin-top: 5px;
        transition: transform 0.5s;
      }

      span {
        display: none;
      }

    }

    .block-title span {
      font-size: 0.9rem;

      &.qty {
        display:none;
      }
    }

    .block-content {
    }

    #minicart-content-wrapper {
      padding: 1rem;
    }

    .minicart-items-wrapper {
      border: 1px solid #efefef;
      border-left: 0;
      border-right: 0;
      overflow-x: auto;
      padding: 0;
      border-top: none;
      margin: 0px;
      height: auto !important;
      max-height: 210px;
      overflow: auto;

      .product {
        padding: 0;
        color: black;
        display: flex;
        padding: 10px 0 0;
      }

      .price-container {
        span {
          margin: 0;
        }
      }

      .details-qty {
        margin: 0;

        .label {
          display: none;
        }
      }
    }

    .product-item {
      position: relative;
      padding: 0px;

      a {
        color: black;

        &.action.edit {
          display: none;
        }
      }
    }

    .product-item-details {
      font-size: 0.8rem;
      padding: 0 0 0 20px;
      flex: 1;

      .product-item-name {
        width: 100%;
        font-size: .9rem;

        a {
          font-size: .9rem;
        }
      }

      .product-item-pricing {
        display: flex;
        justify-content: space-between;
      }
    }

    .update-cart-item {
      font-size: 0.85rem;
      padding: 3px 10px;
      background-color: $btn-blue;
      border: none;
      text-transform: uppercase;

      &:hover {
        background-color: #0e11d9;
        text-decoration: none;
      }
    }

    .product.options {
      display: none;
    }

    .product.actions {

      .primary {
        display: none;
      }

      .secondary {
        position: absolute;
        top: 0px;
        right: 0px;
      }

    }

    .details-qty {
      font-size: 0.8rem;

      .label {
        font-size: 0.8rem;
        width: initial;

        font-weight: 400;
        margin-right: 10px;
      }
    }

    .items-total {
      font-size: 0.8rem;
      margin-top: 12px;
    }

    .subtotal {
      display: none;

      &.total{
        display: block;
        margin-top: 10px;

        .label {
          display: none;
          font-size: 14px;
        }
      }
    }

    .paypal.after:before {
      content: attr(data-label);
      display: block;
      margin: 5px 5px 10px;
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: bold;
    }
  }
}

.popup-authentication {
  .block-authentication {
      margin-bottom: 10px;
  }
}


.mini-cart-buttons {
  a:before {
    display: none;
  }
}

.navigation-top-links {
  .action:not(.close) {
    padding: 0;
  }
}
