.focus-usp-offset {
  position: relative;
  padding-bottom: 3rem;

  &:last-child {
    padding-bottom: 0;
  }

  a {
    word-break: break-all;
  }
}

.focus-usp-offset__img {
  display: block;

  @include tablet {
    width: 40%;
  }

  @include desktop {
    width: 40%;
    height: auto;
  }

  img {
    width: 100%;
    border-radius: 10px;
  }
}

.focus-usp-offset__titles {

  @media (min-width: 769px) and (max-width: 1023px) {
    position: absolute;
    width: 60%;
    top: 80px;
  }
}

.focus-usp-offset__title {
  margin: 0 0 2rem 0;
  position: relative;

  @include tablet {
    width: 100%;
  }

  @include desktop {
    max-width: 580px;
  }
}

.focus-usp-offset__subtitle {
  font-size: 1.5rem !important;
}

.focus-usp-offset__content {
  width: 100%;
  //color: white;
  padding-top: 30px;
  clear: both;

  @include tablet {
    padding: 20px 0 0 0;
  }

  @include desktop {
    width: 60%;
  }
}


// Tailored block styles //

.focus-tailored__titles {
  @media (min-width: 769px) and (max-width: 1023px) {
    left: 40%;
    padding-left: 20px;
  }
}

.focus-tailored__title {
  @include tablet {
  }

  @include desktop {
    //left: -120px
  }
}

.focus-tailored__content {
  @include desktop {
    padding: 0px 0 20px 1.5rem;
  }
}


// Boost block styles //

.focus-boost {
  @include tablet {
    flex-direction: row-reverse;
  }
  a {
    color: #009FD0;
    font-weight: bold;
  }
}

.focus-boost__img {
  @include tablet {
    float: right;
  }
}


.focus-boost__title {

  @include desktop {
    //right: -285px;
  }

  @include widescreen {
    //right: -200px;
  }
}

.focus-boost__content {
  @include desktop {
    padding: 0px 1.5rem 20px 0;
  }
}
