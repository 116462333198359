.primary-header__nav {
  background: none;
  font-family: $font1;

  a {
    color: #ffffff;
    position: relative;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
  }
}

.primary-header__nav-item {
  transition: color 0.5s;
  font-weight: 600;
  font-family: $font1;
  display: flex;
  padding: 10px;

  &:hover {
    color: grey;
    text-decoration: none;
  }

  span {
    &:first-child {
      margin-right: 10px;
      @include tablet {
        width: auto;
      }
    }

    &:last-child {
      border-radius: 10px;
      @include s-text;
      padding: 3px 10px;
      margin: 0;
      @include tablet {
        display: none;
      }
    }
  }

  &--test {
    &:hover {
      color: $hunter-orange;
    }

    span:last-child {
      background: $hunter-orange;
      color: white;
    }
  }

  &--burn {
    &:hover {
      color: $hunter-yellow;
    }

    span:last-child {
      background: $hunter-yellow;
      color: white;
    }
  }

  &--focus {
    &:hover {
      color: $hunter-blue;
    }

    span:last-child {
      background: $hunter-blue;
      color: white;
    }
  }
}

body[class*="hunter-test"] {
  .primary-header__nav-item--test {
    color: $hunter-orange;
  }
}

body[class*="hunter-burn"] {
  .primary-header__nav-item--burn {
    color: $hunter-yellow;
  }
}

body[class*="hunter-focus"] {
  .primary-header__nav-item--focus {
    color: $hunter-blue;
  }
}

.navbar-burger {
  width: 20px;
  height: 16px;
  display: block;
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  left: 1.25rem;
  z-index: 50;
  @include tablet {
    display: none;
    visibility: hidden;
  }

  span {
    width: 100%;
    height: 2px;
    background: white;
    display: block;
    transform-origin: 0;
    transition: all ease 0.3s;
  }

  &.is-open {
    span {
      &:first-child {
        transform: rotate(45deg);
      }

      &:last-child {
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }
    }
  }
}

#covid {
  height: auto;
  background-color: #000;
  position: relative;
  z-index: 21;
  border-top: 1px solid #fff;

  * {
    font-size: 14px;
  }
}

#covid .container {
  padding: 5px;
}

#covid .column {
  padding: 0;
}

#covid a,
#covid a:focus,
#covid a:hover,
#covid a:visited {
  color: #FFF !important;
}

body[class*="cms-page-view"][class*="focus"] #covid,
body[class*="cms-page-view"][class*="test"] #covid,
body[class*="cms-page-view"][class*="burn"] #covid,
body[class*="catalog-category-view"][class*="focus"] #covid,
body[class*="catalog-category-view"][class*="test"] #covid,
body[class*="catalog-category-view"][class*="burn"] #covid {
  padding-top: 2px;

  .covid-accordion {
    top: 140px;
    @include tablet {
      top: 50px;
    }
  }
}

.covid-accordion {
  display: inline-block;
  margin: auto;

  .accordion-trigger {
    .fa {
      margin-left: 10px;
    }
  }

  .accordion-trigger:not(.active) {
    .fa.fa-chevron-up {
      display: none;
    }
  }

  .accordion-trigger {
    display: block;

    &.active {
      .fa.fa-chevron-down {
        display: none;
      }
    }

    @include tablet {
      display: none;
    }
  }

  .accordion-content {
    text-align: left;

    p {
      line-height: 1.4;
    }

    a {
        text-decoration: underline;
    }

    &.active {
      padding: 10px 0 0 0;
    }

    @include tablet {
      display: block;

      &.active {
        padding: 0;
      }
    }

    @include desktop {
      text-align: center;
    }
  }
}
