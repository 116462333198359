.product-image-container {
  @include tablet {
    display: initial;
  }
}

.bundle-options-container {
	.product-add-form {
	    display: block !important;
	}
}

.product-reviews-summary {
	display:none;
}

.product_info_container {
  display: flex;
  flex-direction: column;

  .product_info_action_container {
    flex-direction: row;
    align-self: flex-end;
    height: 100%;
    width: 100%;
    margin: 0;

    text-align: right;
    .price:before { content: "Now Only:"; display: inline-block; font-size: 16px; vertical-align: text-top; margin-right: 5px; }
    .price { font-size: 24px; font-weight: 600; }
    .box-tocart {
      .fieldset > .field.qty { display: none; }
    }

    .bundle-options-container {
      > .product-add-form > form > div {
        display: inline-block;
        vertical-align: top;

        @include mobile {
          width: 100%;
        }

        &.bundle-options-wrapper #custom_bundle_options {
          text-align: left;
          margin-left: -4px;
          margin-bottom: 0;

          .swatch-bundle-selector {
            text-align: left;
            display: inline-table;

            @include mobile { width: 100%; }
            .field.swatch-option {
              margin: 5px;
              padding: 2px;
              height: 100%;
              display: inline-block;
              float: none;
              border: 2px solid #FFFFFF;
              transition: all 0.5s ease;
              &.active { border-color: #4A4A4A; }
              a { margin-bottom: -5px; }
            }
          }
        }
        &.block-bundle-summary {
          @include mobile { text-align: left; }
        }
      }
    }
    .required { display: none; }
  }
}

.product.data.items {
  max-height: 99999px;
  overflow: hidden;
  border: 1px solid #4A4A4A;
  transition: all 0.5s ease;

  .tab-title-container {
    display: table;
    table-layout: fixed;
    width: 100%;

    > .item.subtitle {
      display: table-cell;
      text-align: center;
      margin: 0;
      padding: $column-gap !important;
      border-left: 1px solid #4a4a4a;
      border-bottom: 1px solid #4a4a4a;
      position: relative;
      cursor: pointer;
      transition: all 0.5s ease;
      &:first-child { border-left: none; }
      &.active { border-bottom-color: #ffffff; }
      .data.switch {  padding: 0; line-height: inherit; box-sizing: unset; margin: 0; border: 0; background: transparent; }
      .data.switch:hover, .data.switch:focus { padding-bottom: 0; }
    }
  }

  .tab-content-container {
    display: table;
    box-sizing: border-box;
    padding: $column-gap;
    width: 100%;
    border: none;
    > .item.content {
      border: none;
    }
  }
}

.product-item .price-box {
  margin: 0;
}

.product-item .price-box .price {
  font-size: inherit;
  white-space: inherit;
}

.hunter-product-info-main {

  .product {
    padding-bottom: 0;
  }

  .price {
    @include xl-text;
    font-weight: 800;
    font-family: $font1;
  }
}

.is-products-view-top {
  background: #f5f5f5;

  input[type="number"] {
    height: 35px;
  }

  .price {
    @include xl-text;
    margin-top: 2rem;
  }

  .label {
    color: $light-black;
    font-size: 1rem;
    margin-right: 10px;
    font-weight: 700;
  }

  .product-desc {
    color: $light-black;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;;
    font-weight: 600;
  }

  .text {
    vertical-align: middle;
    color: $light-black;
    font-size: 1rem;
    font-weight: 400;

    .bold {
      font-weight: 600;
    }

    .desc-icon {
      vertical-align: middle;
    }
  }

  .image > img {
    max-height: 500px;
    width: auto;
    margin: 0 auto;
  }

  .bundle-options-wrapper {
    width: 100%;
    float: none;
    padding: 0;
  }

  .block-bundle-summary {
    float: none;
    width: 100%;
    background: none;
    padding: 0;
  }

  .product-single-qty {
    display: flex;
    align-items: center;

    label {
      margin-right: 20px;
    }

    .btn {
      margin-left: 30px;
    }
  }

  .overview {
    p {
      margin-bottom: 1rem;

      img {
        display: inline-block;
        float: left;
        padding-top: 5px;
        margin-right: 10px;
      }
    }
  }
}

.product-single-ingredients__img {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
  border-radius: 10px;
  overflow: hidden;

  p {
    background-color: $light-black;
    margin: 0;
    font-weight: 800;
    font-family: $font1;
    @include s-text;
    padding: 10px 0;
    margin: 0;
  }

  img {
    width: 100%;
    margin-bottom: -10px;
  }
}

.product-single-ingredients__supplement-facts {
  margin-top: 40px;

  p {
    @include font-size(.8, 1);

    @include tablet {
      @include reg-text;
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 0;
  }

  .columns {
    margin-bottom: 0;
  }

  .border {
    border-bottom: 2px solid black;
    margin: 0;
  }
}

.product-test-demand {
  background-image: url(../images/cms/hunter-test-demand-mob.jpg);
  background-position: right;

  @include tablet {
    background-image: url(../images/cms/hunter-test-demand.jpg);
  }
}

.product-burn-demand {
  background-image: url(../images/cms/hunter-burn-demand-mob.jpg);
  background-position: right top;

  @include tablet {
    background-image: url(../images/cms/hunter-burn-demand.jpg);
    background-position: center;
  }
}

.product-focus-demand {
  background-image: url(../images/cms/hunter-focus-demand-mob.jpg);
  background-position: right top;

  @include tablet {
    background-image: url(../images/cms/hunter-focus-demand.jpg);
    background-position: center;
  }
}

.product-testimonials {
  &:before {
    content: '';
    width: 100%;
    height: 80px;
    display: block;
    position: absolute;
    top: -80px;
    left: 0;
    background: linear-gradient(to top, $light-black, rgba(22,34,37,.3) 60%, rgba(0,0,0,0) 90%);
  }
}
