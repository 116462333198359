.page-wrapper {
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;

  @include tablet {
    min-height: calc(100% - 430px);
  }
}

.page-main {
  @include tablet {
    background-color: #f5f5f5;
    flex-grow: 0;
  }
}

section {
  @include bg-cover;
  position: relative;
}

body {
  color: $light-black;
  font-weight: 400;
}

.messages {
  margin-top: 0;
}

.checkout-cart-index {
  .page.messages {
    display: block;
    position: relative;
    margin: 0;
    padding: 50px 1rem;

    div {
      top: 25px;
      text-align: center;
      margin: auto;
    }

    @include tablet {
      margin: 30px auto 0;
      padding: 0;

      div {
        top: 0;
      }
    }
  }
}

.checkout-index-index {
  .primary-header__logo {
    position: relative;
    left: auto;
    right: auto;
    margin-left: 1rem;
  }

  .my-cart {
    display: none;
  }

  .continue-shopping {
    display: block !important;
    margin-right: 1rem;
  }
}

body[class*="hunter-test"],
body[class*="hunter-burn"],
body[class*="hunter-focus"] {
  .messages  {
    margin-top: 100px;

    @include tablet {
      margin-top: 0;
    }
  }
}
