body {
  font-family: $font2;
  font-size: 1rem;
}

h1, h2, h3, h4, h5 {
  font-family: $font1;
  font-weight: 800;
}

h1 {
  @include xxl-text;
}

h2 {
  @include xl-text;
}

h3 {
  @include l-text;

  &.sm-text {
    margin-bottom: 1rem;
  }
}

h4 {
  @include m-text;
}

h5 {
  @include reg-text;
}

p {
  @include reg-text;
}

a {
  font-size: 1rem;
  border: none;
  color: $hunter-orange;


  &:visited, &:focus {
    color: $hunter-orange
  }

  &.category-title-link {
    color: black;
    text-decoration: none;
  }
}

button {
  background-color: $btn-blue;
  border: none;
  outline: none;

  &:visited, &:focus, &:hover {
    background-color: $btn-blue;
    border: none;
    outline: none;
    color: white;
  }
}
