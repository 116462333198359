.cart.table-wrapper .item .col.item {
  padding: 0;
}

.cart.table-wrapper .product-item-name {
  font-weight: 700;
  color: black;
  font-family: $font1;
  @include m-text;
  margin-bottom: 5px;
}

.cart {
  &.table-wrapper {
    margin-bottom: 40px;

    .item {
    }
  }
}

.cart.main.actions {

  ul {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }

  .btn {
    margin: 0 10px 0 0;
  }
}

.cart-summary {
  float: none;
  width: 100%;
  border-radius: 10px;
  background-color: $lightest-grey;
  padding: 2rem;
}

.cart.table-wrapper .product-item-details {
  padding: 15px 0;
}


.product {
  // padding: 0;
}

.mini-cart-buttons {
  display: flex;
  justify-content: space-between;

  .btn {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    font-size: 0.8rem;
  }
}

#shopping-cart-table {
  .columns .columns {
    margin-right: 0;
    margin-left: 0;
  }
}

.price-including-tax .price, .price-excluding-tax .price {
  // font-weight: 500;
  @include sm-text;
}

.cart.table-wrapper .col.qty, .cart.table-wrapper .col.price, .cart.table-wrapper .col.subtotal, .cart.table-wrapper .col.msrp {

  @media (max-width: $breakpoint-sm) {
    width: 50%;
  }

}

/*German Styling**/
.german-store {
  .mini-cart-buttons .btn {
    font-size: 0.7rem!important;
  }
}
