.focus-icon {
  margin-right: 20px;
  width: 56px;
  height: 56px;
}

.focus-waves {
  position: absolute;
  opacity: .8;

  &.last-wave {
    opacity: .4
  }

  @media (min-width: 1300px) {
    top: -180px;

    .category-ingredients & {
      top: 0
    }
  }
}

.focus-natural-choice {
  &.blue-wave {
    background-position: 40% -78%;
    @include tablet {
      background-position: 0% 62%;
    }
  }
}
.focus-waves--reverse {
  transform: scaleX(-1);

  @media (min-width: 1200px) {
    top: -150px;
  }

  @media (min-width: 1300px) {
    top: -200px;
  }

  @media (min-width: 1400px) {
    top: -300px;
  }
}

.focus-sciene-block__intro {
  text-align: center;

  @include desktop {
    text-align: left;
  }
}

.focus-life__part-parent {
  margin-bottom: 40px;
}

.focus-life__part {
  min-height: 600px;
  display: flex;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
    z-index: 1;
  }

  @include desktop {
    &:before {
      width: 80%;
      height: 100%;
    }
  }
}

.focus-life__part-content {
  width: 100%;
  z-index: 10;
  color: white;
  margin-top: 350px;

  &:before {
    background: linear-gradient(to top, rgba(0,0,0, 0.8), rgba(0,0,0,0.4) , rgba(0,0,0, 0));
  }

  @include desktop {
    width: 40%;
    margin-top: 100px;
    padding: 20px;

    .focus-life__part--home &{
      margin: 0 0 100px;
    }
  }
}

.focus-life__part--work, .focus-life__part--play {
  background-image: url(../images/focus/jpg/at-work.jpg);
  background-image: url(../images/focus/jpg/at-work.webp);
  //background-image: url(../images/focus/jpg/at-work-v2@2x.jpg);
  //background-image: url(../images/focus/jpg/at-work-v2@2x.webp);
  background-position: 28%;
  justify-content: flex-end;
  align-items: flex-end;


  &:before {
    right: 0;
    background: linear-gradient(to top, rgba(0,0,0, 0.8), rgba(0,0,0,0.4) , rgba(0,0,0, 0));
  }

  @include tablet {
    background-position: left;
  }

  @include desktop {
    &:before {
      background: linear-gradient(to left, rgba(0,0,0, 0.7), rgba(0,0,0,0.5) , rgba(0,0,0, 0));
    }
  }
}

.focus-life__part--home {
  background-image: url(../images/focus/jpg/at-home.jpg);
  background-position: 76%;

  &:before {
    left: 0;
    background: linear-gradient(to top, rgba(0,0,0, 0.8), rgba(0,0,0,0.4) , rgba(0,0,0, 0));
  }

  @include tablet {
    background-position: right;
  }

  @include desktop {
    &:before {
      background: linear-gradient(to right, rgba(0,0,0, 0.7), rgba(0,0,0,0.5) , rgba(0,0,0, 0));
    }
  }
}

.focus-life__part--play {
  background-image: url(../images/focus/jpg/at-play.jpg);
  background-image: url(../images/focus/jpg/at-play.webp);
  //background-image: url(../images/focus/jpg/at-play-v2@2x.jpg);
  //background-image: url(../images/focus/jpg/at-play-v2@2x.webp);
}

.focus-product {
  width: 100%;
  max-width: 300px;

  @include tablet {
    max-width: 350px;
  }

  @include desktop {
    max-width: 400px;
  }
}

.focus-safeguards {
  .card {
    padding: 4rem 2rem 3rem;

    img {
      max-width: 250px;
      display: block;
      margin: 40px;
    }
  }

  .card__images {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include tablet-only {
      flex-direction: row
    }
  }
}

.focus-big-difference__card {
  background-image: url(../images/focus/jpg/small-price-focus.jpg);
  background-image: url(../images/focus/jpg/small-price-focus.webp);
  background-size: cover;
  padding: 4rem 2rem;
}

.focus-enjoy-now {
  .guarantee {
    border-radius: 0 0 10px 10px;
  }
}

.focus-enjoy-now__card {
  padding: 0;

  ul {
    justify-content: center;
    flex-flow: wrap;
  }

  li {
    margin: 10px;

    @include desktop {
      margin: 20px;
    }
  }

  img {
    width: 80px;
    height: 80px;

    @include tablet {
      width: 100px;
      height: 100px;
    }

    @include desktop {
      width: auto;
      height: auto;
    }
  }

  .card__content {
    padding: 2rem;
  }
}

.focus-gradient {
  background: linear-gradient(to bottom, $light-black, $hunter-blue);
}

.focus-gradient-1 {
  //background: linear-gradient(to bottom, rgb(0,0,0), rgb(8, 145, 179));
  background: #f9f9f9;
}

.focus-gradient-2 {
  //background: linear-gradient(to bottom, rgb(8, 145, 179) 0, rgb(0,0,0) 60%, rgb(0,0,0) 80%, #0e86a2 100%);
  background: #f9f9f9;
}

.focus-gradient-3 {
  //background: linear-gradient(to bottom, #0e86a2 0, rgb(0,0,0) 80%);
  background: #f9f9f9;
}

.focus-gradient-4 {
  //background: linear-gradient(to bottom, #000, #066a83);
  background: #f9f9f9;
}

.focus-gradient-cta {
  background: linear-gradient(to bottom, #000, #066a83);
}


/**FAQ STYLING**/

.faqs-white {
  .faq-block-content {
    background: #ffffff;
    color: #0c0e12;
    .faq {
      color: #0c0e12;
      .faq__question {
        color: #0c0e12;
        &:hover {
            &:after {
              color: #5a5a5a;
            }
        }
      }

    }
  }
  .faq-block__title h2 {
    background: #fff;
    color: #0c0e12;
    &:before {
      content: '';
      width: 15px;
      height: 25px;
      position: relative;
      display: inline-block;
      background-image: url(../images/cms/faqs-box-icon-black.svg);
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 10px;
    }
  }
}

.focus-table {
  min-width: 370px;

  h2 {
    line-height: 1rem;
    margin: 0;
    padding: 0.5rem;
    .is-size-6 {
      font-weight: 600;
    }
  }
  .is-borderless {
    border: none!important;
  }
  .border-bottom {
    border-bottom: 1px solid #000!important;
  }
  .footnotes {
    padding: 0.5rem;
    p {
      line-height: 1rem;
    }
  }
}

.focus-quote {
  .category-blockquote__quote p {
    @include tablet {
      font-size: 1.85rem;
      line-height: 2.1rem;
    }
  }
}

.white-gradient {
  background: rgba(255,255,255,0.7);
  padding: 0.5rem;
  p  {

  }
  @include tablet {
    padding: 1rem;
  }
}

.blue-wave {
  background-image: url(../images/focus/png/wave-background@2x.png);
  background-image: url(../images/focus/png/wave-background@2x.webp);
  background-size: 2200px 1035px;
  background-position: 0% 62%;
  overflow: hidden;
  @include tablet {
    background-position: 0% 66%;
  }
}
