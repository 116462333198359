.card {
  border-radius: 10px;
  //box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
  box-shadow: none;
  padding: 1rem;
  margin: 0;
  background-color: white;
  position: relative;
  z-index: 20;

  @include tablet {
    height: 100%;
    padding: 2rem;
  }

  p {
    &:last-child {
      margin: 0;
    }
  }

  &.card--dark {
      background:#162225;
      color: #fff;
  }
}

.card-auto-height {
  height: auto;
}

.card--product {
  padding: 10px;
}

.card--grey {
  background-color: $light-black;
  //color: $white;
  //$background-color: $white;
}

.card--white {
  background: $white;
  border: 3px solid;
  p {
    color: #162225;
    &.ingredients-block__title {
      color: inherit;
    }
  }
  .l-text.bold {
    color: inherit;
    background: #fff;
    padding:1rem;
  }
}
.card--product {
  background-color: $white;
  color: #162225;
}

.card--test {
  border: 1px solid #f15a29;
  &--featured {
    border-width: 2px;
    position: relative;
  }
  .card--bundles--featured__featuredtitle {
    p {
      border: 2px solid #f15a29;
    }
  }
}

.card--burn {
  border: 1px solid #faa619;
  &--featured {
    border-width: 2px;
    position: relative;
  }
  .card--bundles--featured__featuredtitle {
    p {
      border: 2px solid #faa619;
    }
  }
}

.card--focus {
  border: 1px solid #009FD0;
  &--featured {
    border-width: 2px;
    position: relative;
  }
  .card--bundles--featured__featuredtitle {
    p {
      border: 2px solid #009FD0;
    }
  }
}
.card-wrapper {
  margin-top: 80px;

  @include tablet {
    margin-top: 120px;
  }
}

.card--infotest {
  padding:0;
  .card-image {
    background: url(../images/cms/test-white-bg.jpg) no-repeat;
    background: url(../images/cms/test-white-bg.webp) no-repeat;
    background-size: 300%;
    background-position: left;
    min-height: 400px;
    @include tablet {
      background-size: 350%;
    }
    @include desktop {
      background-size: 300%;
    }
  }
  .card-content {
    padding: 3rem 1rem;
    p {
      margin: 0;
    }
  }
}
.card--infoburn {
  padding:0;
  .card-image {
    background: url(../images/cms/burn-white-bg.jpg) no-repeat;
    background: url(../images/cms/burn-white-bg.webp) no-repeat;
    background-size: 300%;
    background-position: left;
    min-height: 400px;
    @include tablet {
      background-size: 350%;
    }
    @include desktop {
      background-size: 300%;
    }
  }
  .card-content {
    padding: 3rem 1rem;
    p {
      margin: 0;
    }
  }
}
.card--infofocus {
  padding:0;
  .card-image {
    background: url(../images/cms/focus-white-bg.jpg) no-repeat;
    background: url(../images/cms/focus-white-bg.webp) no-repeat;
    background-size: 300%;
    background-position: left;
    min-height: 400px;
    @include tablet {
      background-size: 350%;
    }
    @include desktop {
      background-size: 300%;
    }
  }
  .card-content {
    padding: 3rem 1rem;
    p {
      margin: 0;
    }
  }
}

body.categorypath-stacks {
  .product {
    padding:0;
    @include tablet {
      padding: 0.75rem;
    }
  }
}
.card--bundles {
  border: 1px solid #6c6c6c;
  box-shadow: 1px 1px 4px #d3d3d3;
  padding: 0.75rem;

  @include tablet {
    padding: 1.5rem;
  }
  &--featured {
    border-color: #000000;
    border-width: 3px;
    position: relative;
    &__featuredtitle {
      justify-content: center;
      p {
        background: #ffffff;
        border: 3px solid #000000;
        border-radius: 10px;
        color: #000;
        font-weight: bold;
        padding: 0.5rem 2rem;
        position: absolute;
        top: -40px;
        text-transform: uppercase;
        i {
          color: #f19609;
          margin-right: 10px;
        }
      }
    }
  }
  /**NORMAL TITLE**/
  &__featuredtitle {
    justify-content: center;
    p {
      background: #ffffff;
      border: 2px solid #6c6c6c;
      border-radius: 10px;
      color: #000;
      font-size: 0.885rem;
      font-weight: bold;
      padding: 0.5rem 2rem;
      position: absolute;
      top: -50px;
      text-transform: uppercase;
      i {
        color: #f19609;
        margin-right: 10px;
      }
    }
  }
  ul {
    font-weight: 600;
    margin-bottom:1rem;
    li {
      margin-bottom: 0.25rem;
    }
  }
}

.card-with-icon {
  img {
    margin: -90px auto 10px;
    max-width: 130px;
    display: block;

    @include tablet {
      margin: -120px auto 20px;
      max-width: 180px;
    }
  }
}

.card-with-product {
  margin: 120px 0 0;

  @include tablet {
    margin: 120px 0 -120px;

    &.card--flex {
      display: flex;
      flex-direction: column;
    }

    .equal-height {
      justify-content: space-between;
      height: 100%;
      display: flex;
      flex-flow: column;
    }
  }

  h2 {
    margin-bottom: 0;
  }

  img {
    max-width: 120px;
    position: relative;
    margin-top: -154px;
  }
}

.card-with-product--large {
  img {
    max-width: 200px;
    position: relative;
    margin-top: -200px;
  }
}

.card--gradient {
  &:before {
    content: '';
    height: 8px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.card--gradient-blue {
  &:before {
    background: linear-gradient(to right, $hunter-blue, $blue);
  }
}

.card--gradient-black-to-blue {
  &:before {
    background: linear-gradient(to right , #000000, $blue);
  }
}

.card--gradient-orange-to-blue {
  &:before {
    background: linear-gradient(to right, $hunter-orange, $blue)
  }
}

.card--gradient-yellow-to-blue {
  &:before {
    background: linear-gradient(to right, $hunter-yellow, $blue)
  }
}

.card-parent-offset {
  &.offset-150-350 {
    margin-top: -150px;

    @include tablet {
      margin-top: -350px;
    }
  }

  &.offset-250-350 {
    margin-top: -250px;

    @include tablet {
      margin-top: -350px;
    }
  }

  &.offset-350-250 {
    margin: -350px 0 70px 0;

    @include tablet {
      margin: -250px 0 70px 0;
    }
  }

  &.offset-250 {
    margin: -250px 0 70px 0;
  }

  &.offset-360-310 {
    margin: -360px 0 70px 0;

    @include tablet {
      margin: -310px 0 100px 0;
    }
  }

  &.offset-350 {
    margin-top: -350px;
  }

  &.offset-350--2 {
    margin: -350px 0 100px;

    @include tablet {
      margin: -350px 0 200px;
    }
  }
}

.card--test {
  .product-info__bottom {
    margin-bottom: 1rem;
  }
}
