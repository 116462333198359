.icon--check {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  background-size: cover;
  top: 2px;
  margin-right: 10px;

  body.category-hunter-test &, body[class*='product-hunter-test'] & {
    background-image: url(../images/cms/icon-check-test.svg);
  }
  body.category-stacks & {
    background-image: url(../images/cms/icon-check-green.svg);
  }
  body.category-hunter-burn &, body[class*='product-hunter-burn'] & {
    background-image: url(../images/cms/icon-check-burn.svg);
  }

  body.category-hunter-focus &, body[class*='product-hunter-focus'] & {
    background-image: url(../images/cms/icon-check-focus.svg);
  }
}

.icon--pill {
  background-image: url(../images/test/pill-icon.png);
  position: relative;
  display: inline-block;
  width: 18px;
  height: 16px;
  background-size: cover;
  top: 2px;
  margin-right: 10px;
}
